export const USER_QUERY_KEY = 'user';
export const ENVIRONEMNTS_QUERY_KEY = 'environments';
export const APPLICATIONS_QUERY_KEY = 'applications';
export const APPLICATION_VERSIONS_QUERY_KEY = 'applicationVersions';
export const UNMATCHED_DEVICE_ITEMS = 'unmatchedDeviceItems';
export const DOWNLOADS_QUERY_KEY = 'downloads';
export const CUSTOMER_QUERY_KEY = 'customer';
export const LOGIN_QUERY = 'login';
export const REGISTER_QUERY = 'register';
export const RESEND_VERIFICATION_QUERY = 'resendVerification';
export const SEND_PASSWORD_RESET_QUERY = 'sendPasswordReset';
export const REFRESH_QUERY = 'refresh';
export const PASSWORD_RESET_WITH_TOKEN_QUERY = 'passwordResetWithToken';
export const CUSTOMER_SETUP_ACCOUNT_QUERY = 'customerSetupAccount';
export const COMPANY_USERS_QUERY_KEY = 'companyUsers';
export const INVITE_CUSTOMER_QUERY_KEY = 'invite';
export const CUSTOMERS_QUERY_KEY = 'customers';
export const VALIDATE_CUSTOMER_INVITE_QUERY = 'validateCustomerInvite';
export const DEVICES_QUERY_KEY = 'devices';
export const DEVICE_TAGS_QUERY_KEY = 'deviceTags';
export const WDAC_RESULTS_QUERY_KEY = 'results';
export const WDAC_TABS_DATA_COUNTS_KEY = 'wdacTabsDataCounts';
export const DEVICE_TABS_DATA_COUNTS_KEY = 'deviceTabsDataCounts';
export const RESULTS_TAB_DATA_COUNTS_KEY = 'resultsTabsDataCounts';
export const WDAC_APP_QUERY_KEY = 'wdacApp';
export const WDAC_DEVICE_RESULTS_QUERY_KEY = 'deviceResults';
export const WDAC_DEVICE_RESULT_DETAILS_KEY = 'deviceResultsDetails';
export const WDAC_DEVICE_IMPACTED_DEVICES_KEY = 'wdacAppImpactedDevices';
export const WDAC_APP_MATCHED_APPLICATIONS_KEY = 'wdacAppMatchedApplications';
export const APPLICATION_QUERY_KEY = 'application';
export const DEVICE_QUERY_KEY = 'device';
export const DEVICE_LOGS_QUERY_KEY = 'logFiles';
export const DEVICE_UNMATCHED_FILES_QUERY_KEY = 'deviceUnmatchedFiles';
export const PUBLISHERS_QUERY_KEY = 'publishers';
export const UNASIGNED_FILES_QUERY_KEY = 'unasignedFiles';
export const OUTSIDE_PATH_RULES_QUERY_KEY = 'outsidePathRules';
export const WRITABLE_FOLDERS_QUERY_KEY = 'writableFolders';
export const ENVIRONMENT_NAME_QUERY_KEY = 'environmentName';
export const DEVICES_DASHBOARD_ENFORCEMENT_QUERY_KEY = 'devicesEnforcementDashboard';
export const DEVICES_DASHBOARD_HEALTH_QUERY_KEY = 'devicesHealthDashboard';
export const TAG_DEVICES_COUNT_QUERY_KEY = 'tagDevicesCount';
export const TAG_DEVICES_QUERY_KEY = 'tagDevices';
export const OS_TYPES_QUERY_KEY = 'osTypes';
export const LOG_TYPES_QUERY_KEY = 'logTypes';
export const OS_SKUS_QUERY_KEY = 'osSkus';
export const OS_VERSIONS_QUERY_KEY = 'osVersions';
export const LAST_DOWNLOAD_DATES_KEY = 'lastDownloadDates';
export const FILES_COUNT_KEY = 'filesCount';
export const APP_COUNTS_KEY = 'appCounts';
export const ALL_FILES_COUNT_KEY = 'allFilesCount';
