export const detailsHeadings = [
  {
    title: 'Process Name',
    name: 'processName',
    hasSorting: false,
    width: '24%',
  },
  {
    title: 'Full File Path',
    name: 'fullFilePath',
    hasSorting: false,
    width: '25%',
  },
  {
    title: 'Device WDAC Events Count',
    name: 'deviceWdacEventsCount',
    hasSorting: false,
    width: '13%',
  },
  {
    title: 'Authenticode Hash (SHA-256)',
    name: 'authenticodeHashSha256',
    hasSorting: false,
    width: '25%',
  },
  {
    title: 'Event System Time',
    name: 'eventSystemTime',
    hasSorting: false,
    width: '13%',
  },
];

export const columnsOrderDetails = [
  { name: 'processName', styles: { wordBreak: 'break-word' } },
  { name: 'fullFilePath', styles: { wordBreak: 'break-word' } },
  { name: 'deviceWdacEventsCount', isNumber: true },
  { name: 'authenticodeHashSha256', styles: { wordBreak: 'break-word' } },
  { name: 'eventSystemTime' },
];
