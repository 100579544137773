import React, { useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import TextFieled from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Iconify from '../Iconify';
import IconButton from '../IconButton';
import { useLocation } from 'react-router-dom';

const StyledInput = styled(TextFieled, {
  shouldForwardProp: prop => prop !== 'hasCorner',
})(({ hasCorner }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#4B4B4B',
    borderRadius: hasCorner ? '8px 8px 0px 0px' : '0px',
    padding: '16px 24px',
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiOutlinedInput-root input': {
    padding: 0,
  },
}));

const TableSearch = ({
  searchValue,
  onChangeSearchValue,
  placeholder = 'Enter a keyword',
  onResetSearch,
  disabled = false,
  hasCorner = true,
}) => {
  const { pathname } = useLocation();

  useEffect(
    () => {
      onResetSearch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname],
  );

  return (
    <Box sx={{ position: 'sticky', top: 0, zIndex: 2 }}>
      <StyledInput
        hasCorner={hasCorner}
        disabled={disabled}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="material-symbols:search" color="#6B7280" width={20} height={20} />
            </InputAdornment>
          ),
          ...(searchValue?.length
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onResetSearch}>
                      <Iconify icon="ic:outline-close" color="#DDDDDD" width={20} height={20} />
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : {}),
        }}
        value={searchValue}
        onChange={onChangeSearchValue}
        fullWidth
        placeholder={placeholder}
      />
    </Box>
  );
};

export default TableSearch;
