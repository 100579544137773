import { memo, useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useInView } from 'react-intersection-observer';
import TableExpandedRow from './TableExpandedRow';
import { APPLICATION_VERSIONS_QUERY_KEY, UNMATCHED_DEVICE_ITEMS } from '../../constants/query';
import NoDataCell from '../NoDataCell';
import { pagesText } from '../../constants/pagesText';
import LoadingRows from './LoadingRows';
import { EXPANDED_ROWS_LIMIT, ROW_SOURCES } from '../../constants/table';
import applicationsService from '../../services/applications.service';
import useSubscribeStorage from '../../hooks/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from '../../constants/storage';

const appsExpandedRowColumnsOrder = [
  'environmentApplicationName',
  'softwareCenterName',
  'publisher',
  '',
  'latestVersion',
  'installSize',
  'createdAt',
];

const devicesExpandedColumnsOrder = [
  'authenticodeHashSha256',
  'deviceWdacEventsCount',
];
const ExpandedRow = memo(({ onClick, source, applicationId, activePage, columnsOrder, row = {} }) => {
  const [showAllVersions] = useState(false);
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);
  const expandedRowColumnsOrder = useMemo(() => {
    if (source === ROW_SOURCES.apps) {
      return appsExpandedRowColumnsOrder;
    } else if (source === ROW_SOURCES.files) {
      return devicesExpandedColumnsOrder;
    }

    return columnsOrder;
  }, [source, columnsOrder]);

  const { ref: lastElementRef, inView } = useInView();

  const queryClient = useQueryClient();

  const {
    data: versionsData,
    error: versionsError,
    fetchNextPage: fetchVersionsNextPage,
    hasNextPage: versionsHasNextPage,
    isLoading: versionsLoading,
    isFetchingNextPage: versionsIsFetchingNextPage,
  } =
    useInfiniteQuery(
      [APPLICATION_VERSIONS_QUERY_KEY, applicationId],
      async ({ pageParam = 1 }) =>
        await applicationsService.getApplicationVersions({
          applicationId,
          // TODO: currently page query not working
          page: 1,
          limit: (pageParam - 1) * EXPANDED_ROWS_LIMIT + EXPANDED_ROWS_LIMIT,
        }),
      {
        enabled: source !== ROW_SOURCES.files,
        getNextPageParam: (lastPage, allPages) => {
          const flatAllPages = allPages?.flatMap(page => page.versions);
          if (!showAllVersions) return undefined;

          return flatAllPages.length <= lastPage?.count
            ? flatAllPages.length / EXPANDED_ROWS_LIMIT + 1
            : undefined;
        },
      },
    );

  useEffect(() => {
    return () => {
      queryClient.removeQueries([UNMATCHED_DEVICE_ITEMS, customerEnvId]);
    };
  }, [queryClient, customerEnvId]);
  useEffect(() => {
    if (inView && versionsHasNextPage) {
      fetchVersionsNextPage();
    }
  }, [inView, fetchVersionsNextPage, versionsHasNextPage]);


  const versionsRowData = useMemo(
    () => ({
      versions: versionsData?.pages[versionsData.pages.length - 1]?.versions || [],
      count: versionsData?.pages[0].count || 0,
    }),
    [versionsData],
  );

  const renderNoDataCell = useMemo(() => {
    return versionsError || !versionsRowData.versions?.length;

  }, [versionsError, versionsRowData]);

  if (versionsLoading) {
    return (
      <LoadingRows
        columnCount={expandedRowColumnsOrder.length + 1}
        rowCount={EXPANDED_ROWS_LIMIT}
      />
    );
  }

  return (
    <>
      {renderNoDataCell ? (
        <NoDataCell>{source === ROW_SOURCES.files ? pagesText.noDataFound : pagesText.noVersionFound}</NoDataCell>
      ) : (
        <>
          <TableExpandedRow
            onClick={onClick}
            data={versionsRowData.versions}
            ref={lastElementRef}
            columnsOrder={expandedRowColumnsOrder}
            source={source}
          />
          {versionsIsFetchingNextPage ? (
            <LoadingRows
              columnCount={expandedRowColumnsOrder.length + 1}
              rowCount={EXPANDED_ROWS_LIMIT}
            />
          ) : null}
        </>
      )}
    </>
  );
});

export default ExpandedRow;
