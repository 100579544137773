import api from './api';

const API_PREFIX = '/wdac';
const API_DEVICE_PREFIX = '/device';

const getWDACResults = ({ limit, page, customerEnvId, query }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/wdacResults${query}`, {
    params: {
      Page: page,
      Limit: limit,
    },
  });

const getWDACApp = ({ globalApplicationVersionId }) =>
  api.get(`${API_PREFIX}/globalApplication/${globalApplicationVersionId}`);

const getWdacAppDetails = ({ customerEnvId, appId, page, limit, query }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/${appId}/wdacResultItemDetails${query}`, {
    params: {
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const getWdacAppImpacredDevices = ({ customerEnvId, appId, page, limit, query }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/${appId}/impactedDevices${query}`, {
    params: {
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const getWdacAppMatchedApplications = ({ customerEnvId, appId, page, limit, query }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/${appId}/matchedApplications${query}`, {
    params: {
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const getDeiveWDACResults = ({ limit, page, customerEnvId, deviceId, query }) =>
  api.get(`${API_DEVICE_PREFIX}/${customerEnvId}/${deviceId}/wdacResults${query}`, {
    params: {
      Page: page,
      Limit: limit,
    },
  });

const getAppWdacTabsDataCounts = ({ customerEnvId, globalApplicationVersionId }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/${globalApplicationVersionId}/appWdacTabsDataCounts`);

const getWdacResultsTabsDataCounts = ({ customerEnvId }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/wdacResultsTabsDataCounts`);

const WDACResultsService = {
  getWDACApp,
  getWDACResults,
  getDeiveWDACResults,
  getWdacAppDetails,
  getAppWdacTabsDataCounts,
  getWdacResultsTabsDataCounts,
  getWdacAppImpacredDevices,
  getWdacAppMatchedApplications,
};

export default WDACResultsService;
