import { useCallback, useMemo } from 'react';
import useInfiniteFetch from '../../../../../../queries/useInfiniteFetch';
import useSubscribeStorage from '../../../../../../hooks/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';
import { WDAC_DEVICE_IMPACTED_DEVICES_KEY } from 'src/constants/query';
import { EXPANDED_ROWS_LIMIT } from 'src/constants/table';
import WDACResultsService from 'src/services/wdac-results.service';
import useQueryParams from 'src/hooks/useQueryParams';
import { parseQueryToApiQuery } from 'src/utils/query.utils';

const useGetAppImpacredDevices = ({ appId }) => {
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);

  const {
    queryObject: { searchValue: initialSearchValue, filters = {} },
  } = useQueryParams();

  const query = useMemo(
    () =>
      parseQueryToApiQuery({
        filters,
        searchValue: initialSearchValue,
      }),
    [filters, initialSearchValue],
  );

  const fetchFunction = useCallback(
    ({ page, limit }) =>
      WDACResultsService.getWdacAppImpacredDevices({
        limit,
        page,
        appId,
        customerEnvId,
        query,
      }),
    [appId, customerEnvId, query],
  );
  const response = useInfiniteFetch({
    queryKey: [WDAC_DEVICE_IMPACTED_DEVICES_KEY, query, customerEnvId, appId],
    fetchFunction,
    expandedRowsLimit: EXPANDED_ROWS_LIMIT,
    itemName: 'results',
  });

  return {
    ...response,
  };
};

export default useGetAppImpacredDevices;
