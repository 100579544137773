export const isDateString = value => {
  if (value instanceof Date) return true;
  if (!value || typeof value !== 'string') return false;

  const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z?$/;
  if (isoDatePattern.test(value)) return true;

  const versionPattern = /^\d+(\.\d+)+$/;
  if (versionPattern.test(value)) return false;

  const containsLetters = /[a-zA-Z]/.test(value);
  const containsNumbers = /\d/.test(value);
  if (containsLetters && containsNumbers) return false;

  const nonDatePattern = /[a-zA-Z]+\s\d{4}$/;
  if (nonDatePattern.test(value)) return false;

  // Check if JavaScript can correctly parse the date
  const date = new Date(value);
  return !isNaN(date.getTime()) && date.toString() !== 'Invalid Date';
};

export const getDateSelectedDayAgo = day => {
  const pastDay = new Date().getDate() - day + 1;
  const pastDateMiliseconds = new Date().setDate(pastDay);

  return new Date(pastDateMiliseconds);
};
