import { memo, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import useQueryParams from '../hooks/useQueryParams';
import { escapeRegExp } from 'src/utils/helpers';

export const TableCellTypography = styled(Typography)(({ theme }) => ({
  color: '#DDDDDD',
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(12),
  wordBreak: 'break-word',
  lineHeight: '22px',
  '& .highlight': {
    backgroundColor: '#7DFF92',
    color: 'black',
    fontWeight: 'bold',
    padding: '0 2px',
  },
}));

const formatTableCell = (value, isNumber) => {
  return !!value ? value : isNumber ? 0 : '-';
};

const GenericTableCell = memo(
  ({
    value,
    isNumber,
    styles = {},
    tooltipProps = { text: '', placement: 'bottom' },
    highlightText = true,
    ...props
  }) => {
    const {
      queryObject: { searchValue },
    } = useQueryParams();

    const highlightedText = useMemo(() => {
      if (!searchValue || typeof value !== 'string' || !highlightText) {
        return formatTableCell(value, isNumber);
      }

      const escapedSearchValue = escapeRegExp(searchValue);
      const regex = new RegExp(`(${escapedSearchValue})`, 'gi');

      const replacedValue = value.replace(regex, '<span class="highlight">$1</span>');

      return formatTableCell(replacedValue, isNumber);
    }, [searchValue, value, highlightText, isNumber]);

    return (
      <TableCell {...props} sx={{ color: 'inherit' }}>
        <Tooltip
          title={tooltipProps.text}
          arrow
          disableHoverListener={!tooltipProps.text}
          placement={tooltipProps.placement}>
          <TableCellTypography
            sx={{ ...styles }}
            dangerouslySetInnerHTML={{ __html: highlightedText }}
          />
        </Tooltip>
      </TableCell>
    );
  },
);

export default memo(GenericTableCell);
