import TokenService from '../services/token.service';
import CustomerIdService from '../services/customer-id.service';

export const logout = () => {
  TokenService.removeAccessToken();
  TokenService.removeRefreshToken();
  TokenService.removeAccessTokenLastRefresh();
  CustomerIdService.removeId();
};

export const filterObjectArray = (array, property) => {
  const seen = new Set();
  return array.filter(obj => {
    const value = obj[property];
    if (!seen.has(value)) {
      seen.add(value);
      return true;
    }
    return false;
  });
};

export const formatInviteStatus = ({ status, expirationDate }) => {
  if (!expirationDate || !status) return '';
  if (status === 'accepted') return 'Accepted';

  const expirationDateObject = new Date(expirationDate);
  const isExpired = expirationDateObject.getTime() < new Date().getTime();

  return isExpired ? 'Expired' : 'Pending';
};

export const createSearchParamsString = object => {
  if (!object || !Object.values(object).length) return '';

  const searchParams = new URLSearchParams();

  Object.entries(object).forEach(([key, value]) => {
    if (value) {
      searchParams.append(key, value);
    }
  });

  return '?' + searchParams.toString();
};

export const getTagDetails = (selectedOptions, addedTag) => {
  const tagNames = [...selectedOptions.map(tag => tag.name), addedTag.name];
  const tagIds = [...selectedOptions.map(tag => tag.id), addedTag.id];
  return [tagNames, tagIds];
};

export const numDigitsCount = number =>
  (Math.log10((number ^ (number >> 31)) - (number >> 31)) | 0) + 1;

export function formatBytes(bytes) {
  if (typeof bytes !== 'number' || bytes < 0) {
    return '0 KB';
  }
  const mb = 1024;
  const kilobytes = bytes / mb;
  const megabytes = kilobytes / mb;

  if (megabytes >= 1) {
    return `${megabytes.toFixed(2)} MB`;
  } else {
    return `${kilobytes.toFixed(2)} KB`;
  }
}

export const highlightText = (text, searchValue) => {
  if (!searchValue || typeof text !== 'string') return text;
  const regex = new RegExp(`(${searchValue})`, 'gi');
  return text.replace(regex, `<span class="highlight">$1</span>`);
};

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
