import { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/Iconify';

const RefreshButton = ({ onRefresh }) => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = async () => {
    setIsRefreshing(true);
    try {
      await onRefresh();
      enqueueSnackbar('Device list successfully refreshed.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar('Failed to refresh device list.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <Button
      size="small"
      sx={{ position: 'absolute', top: '-34px', left: 0 }}
      disabled={isRefreshing}
      startIcon={
        isRefreshing ? (
          <CircularProgress size={20} sx={{ p: '2px' }} />
        ) : (
          <Iconify icon="material-symbols:refresh" width={20} height={20} />
        )
      }
      onClick={handleClick}>
      Refresh
    </Button>
  );
};

export default RefreshButton;
