import React, { memo, useCallback, useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Box,
  Stack,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import useFetchUnmatchedFiles from './useFetchUnmatchedFiles';
import useResetSearchParams from 'src/hooks/useResetSearchParams';
import useQueryParams from 'src/hooks/useQueryParams';
import useDebounceHandler from 'src/hooks/useDebounceHandler';
import UnmatchedFilesAccordion from './UnmatchedFilesAccordion';
import LoadingRows from '../../../../components/Table/LoadingRows';
import { defaultFilterUnmatchedFiles, MAIN_TABLE_HEADERS } from './constants';
import { defaultSearchParams, reversedSortMap, sortingMethods } from '../../../../constants/table';
import TableSearch from 'src/components/elements/TableSearch';
import TableHeadTypography from 'src/components/TableHeadTypography';
import useTypographyMediaQuery from 'src/hooks/useTypographyMediaQuery';
import useTableSort from 'src/hooks/useTableSort';

const UnmatchedFilesTable = () => {
  const { deviceId } = useParams();

  useResetSearchParams();

  const {
    queryObject: { filters = {}, searchValue: initialSearchValue },
    changeFieldInURL,
    onApplySearchParams,
  } = useQueryParams();

  const [searchValue, setSearchValue] = useState(initialSearchValue);

  const { mediumScreen, largeScreen, smallScreen } = useTypographyMediaQuery();

  const { onSort, onResetSortField, getListItemSortMethod, getCellSortMethod } = useTableSort({
    filters,
    onApplySearchParams,
    searchValue,
    defaultFilters: defaultFilterUnmatchedFiles,
  });

  const {
    isLoading,
    data: logs,
    lastElementRef,
    isFetching,
  } = useFetchUnmatchedFiles({ deviceId });

  const debounceQueryHandler = useDebounceHandler((key, value) => {
    changeFieldInURL([{ key, value }]);
  }, 500);

  const onChangeSearchValue = useCallback(
    event => {
      setSearchValue(event.target.value);
      debounceQueryHandler('search', event.target.value);
    },
    [debounceQueryHandler],
  );

  const onCellSort = useCallback(
    ({ field, sortingCellField, sortMethod, sortFieldName }) => {
      const reversedSortOrder =
        sortMethod === sortingMethods.initial ? sortingMethods.asc : reversedSortMap[sortMethod];
      onSort(field, reversedSortOrder, sortingCellField, sortFieldName);
    },
    [onSort],
  );

  const onResetSearch = useCallback(() => {
    setSearchValue('');
    changeFieldInURL([defaultSearchParams]);
  }, [setSearchValue, changeFieldInURL]);

  return (
    <Box sx={{ position: 'relative' }}>
      <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'column' }}>
        <TableSearch
          onResetSearch={onResetSearch}
          searchValue={searchValue}
          onChangeSearchValue={onChangeSearchValue}
        />

        <TableContainer
          component={Paper}
          sx={{ maxHeight: 'calc(100vh - 368px)', overflow: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#3f3f3f' }}>
                {MAIN_TABLE_HEADERS.map(({ Component, ...headingProps }, index) => {
                  const sortMethod = !getListItemSortMethod
                    ? sortingMethods.initial
                    : getListItemSortMethod(headingProps?.name);

                  return (
                    <TableCell
                      key={`${headingProps.title}-${index}`}
                      mediumScreen={mediumScreen}
                      largeScreen={largeScreen}
                      sx={{
                        zIndex: 3,
                        ...(headingProps?.width ? { width: headingProps.width } : {}),
                      }}>
                      <Stack gap={1} direction="row" alignItems="center">
                        {!!Component ? (
                          <Component
                            title={headingProps.title}
                            name={headingProps.name}
                            onDoubleClick={onResetSortField}
                            onCellSort={onCellSort}
                            getCellSortMethod={getCellSortMethod}
                            filterFieldName={headingProps.filterFieldName}
                            sortingCellField={headingProps.sortingCellField}
                            sortFieldName={headingProps.sortFieldName}
                            field={headingProps.field}
                          />
                        ) : (
                          <TableHeadTypography
                            onDoubleClick={() => onResetSortField(headingProps.name)}
                            mediumScreen={mediumScreen}
                            largeScreen={largeScreen}
                            smallScreen={smallScreen}
                            hasSorting={sortMethod !== sortingMethods.initial}>
                            {headingProps.title}
                          </TableHeadTypography>
                        )}
                      </Stack>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <LoadingRows columnCount={MAIN_TABLE_HEADERS.length} rowCount={3} />
              ) : logs.length > 0 ? (
                logs.map((log, index) => (
                  <UnmatchedFilesAccordion
                    key={log.id}
                    deviceId={deviceId}
                    log={log}
                    logs={logs}
                    index={index}
                    lastElementRef={lastElementRef}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={MAIN_TABLE_HEADERS.length}
                    sx={{ textAlign: 'center', padding: '60px', color: '#fff' }}>
                    No matches
                  </TableCell>
                </TableRow>
              )}
              {!isLoading && isFetching && (
                <LoadingRows columnCount={MAIN_TABLE_HEADERS.length} rowCount={3} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default memo(UnmatchedFilesTable);
