import { memo } from 'react';
import Box from '@mui/material/Box';
import Iconify from './Iconify';
import { colors } from '../theme/palette/darkPalette';
import { sortingMethods } from 'src/constants/table';

const SortingArrow = ({ onClick, sortMethod, hasSorting }) => (
  <Box width={16} height={16}>
    <Iconify
      onClick={onClick}
      icon={sortMethod !== sortingMethods.asc ? 'ion:arrow-down' : 'ion:arrow-up'}
      color={hasSorting && sortMethod !== sortingMethods.initial ? colors.green : '#fff'}
      width={16}
      height={16}
      sx={{
        cursor: 'pointer',
        width: 20,
        height: 20,
      }}
    />
  </Box>
);

export default memo(SortingArrow);
