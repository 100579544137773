import EnvironmentGuard from '../../../../components/EnvironmentGuard';
import ResultsTable from './ResultsTable';
import useFetchWDACResults from 'src/queries/useFetchWDACResults';
import { columnsOrder, secondHeadings, topHeading } from './constants';

const Win32Apps = () => {
  const { isLoading, data: results, lastElementRef, isFetching } = useFetchWDACResults();

  return (
    <EnvironmentGuard>
      <ResultsTable
        isLoading={isLoading}
        results={results}
        lastElementRef={lastElementRef}
        isFetching={isFetching}
        topHeading={topHeading}
        secondHeadings={secondHeadings}
        columnsOrder={columnsOrder}
      />
    </EnvironmentGuard>
  );
};

export default Win32Apps;
