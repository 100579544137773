import React, { useMemo } from 'react';
import { TableRow, TableCell } from '@mui/material';
import useQueryParams from '../../../../hooks/useQueryParams';
import { TableCellTypography } from 'src/components/GenericTableCell';
import { formatDate } from 'src/utils/formatTime';
import { highlightText } from 'src/utils/helpers';
import { useNavigate } from 'react-router-dom';
import { PORTAL_ROUTE } from 'src/constants/routes';
import { WDAC_APP_PREVIOUS_PATH } from 'src/pages/wdac-results/tabs/Win32Apps/constants';
import { tableRowColor } from 'src/constants/table';

const ResultItemAccordion = ({ result, index, lastElementRef, results }) => {
  const navigate = useNavigate();

  const navigateToApps = () => {
    const currentPath = window.location.pathname;
    sessionStorage.setItem(WDAC_APP_PREVIOUS_PATH, currentPath);
    navigate(`${PORTAL_ROUTE}/wdac-apps/${result.id}/affected-binaries`);
  };

  const {
    queryObject: { searchValue },
  } = useQueryParams();

  const highlightedArpDisplayName = useMemo(
    () => highlightText(result?.arpDisplayName, searchValue),
    [result?.arpDisplayName, searchValue],
  );

  const highlightedArpDisplayVersion = useMemo(
    () => highlightText(result?.arpDisplayVersion, searchValue),
    [result.arpDisplayVersion, searchValue],
  );

  const highlightedArpPublisher = useMemo(
    () => highlightText(result?.arpPublisher, searchValue),
    [result?.arpPublisher, searchValue],
  );

  return (
    <>
      <TableRow
        key={result.id}
        ref={index === results.length - 1 ? lastElementRef : null}
        onClick={navigateToApps}
        sx={{
          cursor: 'pointer',
          '&:nth-of-type(even)': {
            backgroundColor: tableRowColor,
          },
        }}>
        <TableCell>
          <TableCellTypography dangerouslySetInnerHTML={{ __html: highlightedArpDisplayName }} />
        </TableCell>
        <TableCell>
          <TableCellTypography dangerouslySetInnerHTML={{ __html: highlightedArpDisplayVersion }} />
        </TableCell>
        <TableCell>
          <TableCellTypography dangerouslySetInnerHTML={{ __html: highlightedArpPublisher }} />
        </TableCell>
        <TableCell>
          <TableCellTypography>{result?.estimatedLaunchAttemptsLast24Hours}</TableCellTypography>
        </TableCell>
        <TableCell>
          <TableCellTypography>{result?.estimatedLaunchAttemptsLast30Days}</TableCellTypography>
        </TableCell>
        <TableCell>
          <TableCellTypography>{result?.estimatedLaunchAttemptsLast90Days}</TableCellTypography>
        </TableCell>
        <TableCell>
          <TableCellTypography>
            {result?.lastLaunchTime ? formatDate(result?.lastLaunchTime) : ''}
          </TableCellTypography>
        </TableCell>
        <TableCell>
          <TableCellTypography>{result?.managedApplicationMatchCount || 0}</TableCellTypography>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ResultItemAccordion;
