import { forwardRef, memo, useCallback, useMemo, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import IconButton from '../IconButton';
import Iconify from '../Iconify';
import ExpandedRow from './ExpandedRow';
import TableNameCell from './TableNameCell';
import GenericTableCell from '../GenericTableCell';
import ConditionComponent from '../ConditionComponent';
import GenericCell from './GenericCell';
import { isDateString } from '../../utils/date.util';
import { formatDate } from '../../utils/formatTime';
import { PORTAL_ROUTE, WDAC_ROUTE } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { ROW_SOURCES, tableRowColor } from 'src/constants/table';
import { WDAC_APP_PREVIOUS_PATH } from 'src/pages/wdac-results/tabs/Win32Apps/constants';

/**
 * @typedef RowProps
 * @property {Object} row
 */

/**
 * Row component.
 * @param {RowProps} props - props of component
 */

const Row = forwardRef(
  (
    {
      row,
      columnData,
      ActionComponent,
      hasActions = true,
      markedRows = [],
      changeMarkedItem,
      hasSelection = false,
      isClickable = false,
      renderCustomCell,
      rowClickHandler,
      source,
      isExpired,
      highlightText,
      withTwoColors = false,
    },
    ref,
  ) => {
    const isRowActive = markedRows[row?.email];
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const onToggleOpen = useCallback(
      event => {
        event?.stopPropagation();
        setOpen(prevOpen => !prevOpen);
      },
      [setOpen],
    );

    const navigateToApp = useCallback(
      id => {
        navigate(`${PORTAL_ROUTE}/apps/${id}/general`);
      },
      [navigate],
    );

    const navigateToDeviceDetails = useCallback(
      id => {
        navigate(`${PORTAL_ROUTE}/devices/${id}/general`);
      },
      [navigate],
    );

    const navigateToWDAC = useCallback(() => {
      navigate(WDAC_ROUTE);
    }, [navigate]);

    const navigateToWDACApp = useCallback(
      id => {
        const currentPath = window.location.pathname;
        sessionStorage.setItem(WDAC_APP_PREVIOUS_PATH, currentPath);
        navigate(`${PORTAL_ROUTE}/wdac-apps/${id}/affected-binaries`);
      },
      [navigate],
    );

    const onChangeMarkedItem = useCallback(() => {
      const newMarkedRows = { ...markedRows };
      if (newMarkedRows[row?.email]) {
        delete newMarkedRows[row.email];
      } else {
        newMarkedRows[row.email] = row;
      }

      changeMarkedItem(newMarkedRows);
    }, [row, changeMarkedItem, markedRows]);

    const rowExpiredStyle = useMemo(() => {
      return isExpired ? { backgroundColor: '#636363', opacity: '0.5' } : {};
    }, [isExpired]);

    const renderCell = useCallback(
      (column, params) => {
        switch (column?.name || column) {
          case 'firstName':
            return (
              <GenericCell
                cellValue={row.firstName}
                isRowActive={params.isRowActive}
                {...column?.props}
              />
            );
          case 'environmentApplicationName':
            return (
              <TableNameCell
                key={row.environmentApplicationName}
                name={row.environmentApplicationName}
                imgSrc={row.icon}
                isRowActive={params.isRowActive}
                {...column?.props}
              />
            );
          default:
            return (
              <GenericTableCell
                highlightText={highlightText}
                key={row[column?.name || column]}
                isNumber={column?.isNumber}
                value={
                  column?.shouldHideValue
                    ? ''
                    : isDateString(row[column?.name || column])
                      ? formatDate(row[column?.name || column])
                      : row[column?.name || column]
                }
                styles={column?.styles || {}}
                tooltipProps={column?.tooltipProps}
                {...column?.props}
              />
            );
        }
      },
      [row, highlightText],
    );

    const onRowClick = useCallback(
      event => {
        if (hasSelection) {
          onChangeMarkedItem(row);

          return;
        }

        if (source === ROW_SOURCES.apps || source === ROW_SOURCES.files) {
          onToggleOpen(event);
        }

        if (source === ROW_SOURCES.devices) {
          navigateToDeviceDetails(row.id);
        }

        if (source === ROW_SOURCES.wdac) {
          navigateToWDAC();
        }

        if (source === ROW_SOURCES.wdacApps) {
          navigateToWDACApp(row.id);
        }
        if (source === ROW_SOURCES.app) {
          navigateToApp(row.id);
        }
      },
      [
        hasSelection,
        onChangeMarkedItem,
        row,
        onToggleOpen,
        source,
        navigateToDeviceDetails,
        navigateToWDAC,
        navigateToWDACApp,
        navigateToApp,
      ],
    );

    return (
      <>
        <TableRow
          ref={ref}
          onClick={onRowClick}
          sx={{
            backgroundColor: isRowActive ? '#ecefed' : '#4B4B4B',
            opacity: isRowActive ? 0.7 : 1,
            color: isRowActive ? '#000' : '#DDDDDD',
            cursor: hasSelection || isClickable ? 'pointer' : 'default',
            ...rowExpiredStyle,
            ...(withTwoColors
              ? {
                  '&:nth-of-type(even)': {
                    backgroundColor: tableRowColor,
                  },
                }
              : {}),
          }}>
          {columnData.map(column => {
            if (renderCustomCell) {
              return renderCustomCell(column, row);
            }

            return renderCell(column, {
              isRowActive,
            });
          })}
          <ConditionComponent
            condition={!ActionComponent && hasActions}
            renderElement={
              <TableCell>
                <Stack direction="row" justifyContent="center">
                  <IconButton
                    aria-label="expand row"
                    onClick={onToggleOpen}
                    selected={open}
                    color="#DDDDDD"
                    shape="rounded">
                    <Iconify
                      icon={
                        open ? 'iconamoon:arrow-up-2-duotone' : 'iconamoon:arrow-right-2-duotone'
                      }
                      color="#DDDDDD"
                      width={24}
                      height={24}
                    />
                  </IconButton>
                </Stack>
              </TableCell>
            }
          />
          <ConditionComponent
            condition={!!ActionComponent && hasActions}
            renderElement={<ActionComponent />}
          />
        </TableRow>

        {open && (
          <ExpandedRow
            key={row.id}
            source={source}
            applicationId={row.id}
            columnsOrder={columnData}
            onClick={rowClickHandler}
            row={row}
          />
        )}
      </>
    );
  },
);

export default memo(Row);
