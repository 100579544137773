import { format } from 'date-fns';

const monthMapping = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const minDate = new Date(0);

export function formatUtcToLocalDateTime(date) {
  if (!date) {
    return;
  }
  const x = new Date(date);
  if (x instanceof Date && !isNaN(x) && x > minDate) {
    return x.toLocaleString();
  }
  return '';
}

export function formatUTCDateToText(date) {
  if (!date) {
    return;
  }
  const x = new Date(date);
  if (x instanceof Date && !isNaN(x)) {
    return `${monthMapping[x.getUTCMonth()]} ${x.getUTCDate()}, ${x.getUTCFullYear()}`;
  }
  return '';
}

export function getYearsBetween(date1, date2) {
  if (!date1 || !date2) {
    return;
  }
  const x = new Date(date1);
  const y = new Date(date2);
  if (x instanceof Date && !isNaN(x) && y instanceof Date && !isNaN(y)) {
    // https://stackoverflow.com/questions/4060004/calculate-age-given-the-birth-date-in-the-format-yyyymmdd
    let age = date2.getFullYear() - date1.getFullYear();
    const m = date2.getMonth() - date1.getMonth();
    if (m < 0 || (m === 0 && date2.getDate() < date1.getDate())) {
      age--;
    }
    return age;
  }
}

export function formatDateShortUTC(date) {
  if (date) {
    const x = new Date(date);
    if (isNaN(x.getYear())) return null;
    return x.toISOString().slice(0, 10);
  }
}

export const decrementedDayIso = date => {
  if (!date) return '';

  const newDate = new Date(date);
  const day = newDate.getDate() - 1;
  const month = newDate.getMonth();
  const year = newDate.getFullYear();

  return new Date(year, month, day);
};

export const formatDate = (date, dateFormat = 'yyyy-MM-dd HH:mm') =>
  !date ? '' : format(new Date(date), dateFormat);
