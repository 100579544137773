import { forwardRef } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import TableNameCell from './TableNameCell';
import GenericTableCell from '../GenericTableCell';
import SecurityActions from '../DownloadActions';
import { isDateString } from '../../utils/date.util';
import { formatDate } from '../../utils/formatTime';
import { ROW_SOURCES } from '../../constants/table';

/**
 *
 * @typedef TableExpandedRowProps
 * @property {Object} row
 */

/**
 * TableExpandedRow components
 *
 * @param {TableExpandedRowProps} props - props of component
 */

const TableExpandedRow = forwardRef(
  ({ onClick, data, showActions = true, columnsOrder,source }, ref) => {
    const renderCell = (column, item) => {
      const cellMaps = {
        environmentApplicationName: (
          <TableNameCell
            key={item.environmentApplicationName}
            name={item.environmentApplicationName}
            imgSrc={item.icon}
          />
        ),
      };
      return (
        cellMaps[column] || (
          <GenericTableCell
            key={item[column]}
            value={isDateString(item[column]) ? formatDate(item[column]) : item[column]}
          />
        )
      );
    };
    return (
      <>
        {data.map((version, index) => (
          <TableRow
            onClick={() => onClick(version.id)}
            ref={index === data.length - 1 ? ref : null}
            key={version.id}
            sx={{
              backgroundColor: '#393939',
              cursor: 'pointer',
            }}>
            {columnsOrder.map(column => renderCell(column, version))}
            <TableCell
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: 81,
              }}>
              {source !== ROW_SOURCES.files ? <Stack direction="row">
                <SecurityActions policies={version.policies} versionId={version.id} />
              </Stack> : null}
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  },
);

export default TableExpandedRow;
