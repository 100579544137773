export const EXPANDED_ROWS_LIMIT = 15;

export const ROW_SOURCES = {
  apps: 'apps',
  devices: 'devices',
  wdac: 'wdac',
  files: 'files',
  wdacApps: 'wdacApps',
  app: 'app',
};

export const sortingMethods = {
  asc: 'asc',
  desc: 'desc',
  initial: 'initial',
};

export const reversedSortMap = {
  [sortingMethods.asc]: sortingMethods.desc,
  [sortingMethods.desc]: sortingMethods.asc,
};

export const defaultSearchParams = { key: 'search', value: '' };

export const tableRowColor = '#ffffff14'