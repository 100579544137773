import { useCallback, useMemo } from 'react';
import useInfiniteFetch from './useInfiniteFetch';
import { EXPANDED_ROWS_LIMIT } from '../constants/table';
import useSubscribeStorage from '../hooks/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';
import WDACResultsService from 'src/services/wdac-results.service';
import { WDAC_DEVICE_RESULTS_QUERY_KEY } from 'src/constants/query';
import useQueryParams from '../hooks/useQueryParams';
import { parseQueryToApiQuery } from 'src/utils/query.utils';

const useFetchDeviceWDACResults = ({ deviceId }) => {
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);

  const {
    queryObject: { filters = {}, searchValue: initialSearchValue, ...rest },
  } = useQueryParams();

  const query = useMemo(
    () => parseQueryToApiQuery({ filters, searchValue: initialSearchValue, ...rest }),
    [filters, initialSearchValue, rest],
  );

  const fetchFunction = useCallback(
    ({ page, limit }) =>
      WDACResultsService.getDeiveWDACResults({
        limit,
        page,
        customerEnvId,
        deviceId,
        query,
      }),
    [customerEnvId, deviceId, query],
  );

  const response = useInfiniteFetch({
    queryKey: [WDAC_DEVICE_RESULTS_QUERY_KEY, query, deviceId, customerEnvId],
    fetchFunction,
    expandedRowsLimit: EXPANDED_ROWS_LIMIT,
    itemName: 'results',
    noCacheTime: true,
  });

  return {
    ...response,
  };
};

export default useFetchDeviceWDACResults;
