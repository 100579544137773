export const logsColumnsOrder = [
  'logFileName',
  'logFileTypeDisplayName',
  'modified',
  'size',
  'actions',
];

export const logscustomHeader = {
  modified: 'modified',
  size: 'size',
  actions: 'actions',
};

export const logsHeadings = [
  {
    title: 'Name',
    name: 'logFileName',
    hasSorting: false,
    width: '26%',
  },
  {
    title: 'Type',
    name: 'logFileTypeDisplayName',
    hasSorting: false,
    width: '26%',
  },
  {
    title: 'Date',
    name: 'modified',
    hasSorting: false,
    width: '22%',
  },
  {
    title: 'Size',
    name: 'size',
    hasSorting: false,
    width: '14%',
  },
  {
    title: 'Actions',
    name: 'actions',
    hasSorting: false,
    width: '10%',
  },
];
