import { useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Chip from '@mui/material/Chip';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { pagesText } from '../../constants/pagesText';
import useDebounce from '../../queries/auth/useDebounce';
import BackButton from '../../components/BackButton';
import { colors } from '../../theme/palette/darkPalette';
import SuccessButton from '../../components/SuccessButton';
import Iconify from '../../components/Iconify';
import useDialog from '../../hooks/useDialog';
import AlertRemoveTagDialog from './AlertRemoveTagDialog';
import useFetchDeviceTags from '../../queries/useFetchDeviceTags';
import { CircularProgress } from '@mui/material';
import { useOutsideClickHandler } from 'src/hooks/useOutsideClickHandler';

const StyledTextField = styled(TextField)({
  '& svg': {
    fill: '#fff',
  },
  '& label.Mui-focused': {
    color: colors.green,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colors.green,
    },
  },
});

const StyledChip = styled(Chip)({
  border: '0.5px solid #fff',
  backgroundColor: '#3f3f3f',
  '&:hover': {
    backgroundColor: '#3f3f3f',
  },
  '& .MuiChip-deleteIcon': {
    fill: '#fff',
    color: '#fff',

    '&:hover': {
      fill: '#fff',
      color: '#fff',
    },
  },
});

const DeviceTagModal = ({
  isOpen,
  onClose,
  onUpdateDeviceTag,
  onDeleteTag,
  activeTags = [],
  loading,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const autocompleteRef = useRef(null);
  const [removeTagId, setRemoveTagId] = useState(null);
  const isClickedOutside = useRef(false);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(null);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const { ref: lastElementRef, inView } = useInView();

  const {
    open: isRemoveTagDialogOpen,
    onOpen: onRemoveTagDialogOpen,
    onClose: onRemoveTagDialogClose,
  } = useDialog();

  useEffect(() => {
    if (isOpen) {
      setSelectedTags(activeTags);
    }
  }, [isOpen, activeTags]);

  const { fetchNextPage, hasNextPage, isFetching, tags, fetchedData } = useFetchDeviceTags({
    debouncedSearchValue,
    enabled: isOpen,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const onKeyDown = useCallback(
    event => {
      if (event.key === 'Enter') {
        setIsAutocompleteOpen(false);
        if (searchValue) {
          setSelectedTags(prev => [...prev, { name: searchValue }]);
        }
        setSearchValue('');
      }
    },
    [searchValue, setIsAutocompleteOpen],
  );

  const onDialogClose = useCallback(() => {
    onClose();
    setSearchValue('');
    setSelectedTags(null);
  }, [onClose]);

  const onSave = useCallback(async () => {
    const tagIds = selectedTags.filter(el => !!el.id).map(el => el.id);
    const tagNames = selectedTags.filter(el => !el.id).map(el => el.name);

    await onUpdateDeviceTag({
      tagNames: [...tagNames],
      tagIds: [...tagIds],
    });

    // if ((tagName === searchValue && !selectedTag?.name) || tagName === selectedTag?.name) {
    //   onClose();
    //   return;
    // }

    setSearchValue('');
    setSelectedTags(null);
  }, [selectedTags, onUpdateDeviceTag]);

  const onTagRemoveClick = useCallback(
    (event, option) => {
      event.preventDefault();
      event.stopPropagation();
      setRemoveTagId(tags.find(tag => tag.name === option)?.id);
      onDialogClose();
      onRemoveTagDialogOpen();
    },
    [tags, setRemoveTagId, onRemoveTagDialogOpen, onDialogClose],
  );

  const onSearchChange = useCallback(event => {
    setSearchValue(event?.target?.value.trim());
  }, []);

  const onTagRemove = useCallback(() => {
    onDeleteTag({ tagId: removeTagId, tagSearch: debouncedSearchValue });
    onRemoveTagDialogClose();
  }, [removeTagId, debouncedSearchValue, onRemoveTagDialogClose, onDeleteTag]);

  const PopperComponent = useCallback(
    props => (
      <Popper
        {...props}
        id="autocomplete-popper"
        placement="bottom"
        sx={{
          '& .MuiAutocomplete-noOptions': {
            display: 'none',
          },
        }}
      />
    ),
    [],
  );

  const renderInput = useCallback(
    params => (
      <StyledTextField
        {...params}
        InputLabelProps={{
          sx: {
            color: '#fff',
          },
        }}
        onChange={onSearchChange}
        onKeyDown={onKeyDown}
        label="Tags"
      />
    ),
    [onKeyDown, onSearchChange],
  );

  const handleAutocompleteChange = useCallback((e, newTags) => {
    setSelectedTags(newTags);
    setSearchValue('');
  }, []);

  const handleRemoveTag = useCallback(tagToRemove => {
    setSelectedTags(prev => prev.filter(tag => tag.id !== tagToRemove.id));
  }, []);

  useOutsideClickHandler(
    autocompleteRef,
    () => {
      setIsAutocompleteOpen(false);
      isClickedOutside.current = true;
    },
    ['autocomplete-option-text', 'autocomplete-option', 'delete-icon'],
  );

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onDialogClose}
        PaperProps={{
          sx: {
            maxWidth: '460px',
            width: '100%',
          },
        }}>
        <DialogTitle>{pagesText.devices.editTagModal.title}</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1, maxWidth: '350px' }}>
            <Autocomplete
              ref={autocompleteRef}
              multiple
              PopperComponent={PopperComponent}
              disableClearable
              value={selectedTags || []}
              loading={isFetching}
              open={isAutocompleteOpen}
              onOpen={() => setIsAutocompleteOpen(true)}
              onChange={handleAutocompleteChange}
              isOptionEqualToValue={(option, val) => option?.name === val?.name}
              filterOptions={createFilterOptions({ trim: true })}
              options={fetchedData?.data || []}
              renderInput={renderInput}
              renderOption={({ key, ...optionProps }, option) => (
                <Stack
                  {...optionProps}
                  direction="row"
                  justifyContent="space-between"
                  key={key}
                  id="autocomplete-option"
                  ref={tags[tags.length - 1]?.name === option.name ? lastElementRef : null}>
                  <Box id="autocomplete-option-text" sx={{ flex: 1 }}>
                    {option.name}
                  </Box>
                  <IconButton onClick={event => onTagRemoveClick(event, option.name)}>
                    <Iconify icon="ic:round-delete" color="#fff" width={20} height={20} />
                  </IconButton>
                </Stack>
              )}
              renderTags={(value, getTagProps) =>
                value.map((tag, index) => (
                  <StyledChip
                    {...getTagProps({ index })}
                    onClick={() => handleRemoveTag(tag)}
                    label={tag?.name}
                    deleteIcon={
                      <Iconify icon="ic:round-close" color="#fff" width={20} height={20} />
                    }
                  />
                ))
              }
              getOptionLabel={option => option?.name}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <BackButton onClick={onDialogClose}>
            {pagesText.devices.editTagModal.onCloseButton}
          </BackButton>
          <SuccessButton disabled={loading} onClick={onSave}>
            {loading && <CircularProgress sx={{ mr: 1 }} color="inherit" size={16} />}
            Save
          </SuccessButton>
        </DialogActions>
      </Dialog>
      <AlertRemoveTagDialog
        open={isRemoveTagDialogOpen}
        onClose={onRemoveTagDialogClose}
        tagId={removeTagId}
        onSuccess={onTagRemove}
      />
    </>
  );
};

export default DeviceTagModal;
