import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { pagesText } from '../constants/pagesText';
import AlertPopup from './AlertPopup';
import { PORTAL_ROUTE } from '../constants/routes';
import useSubscribeStorage from 'src/hooks/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';

const Description = styled(Typography)(({ theme }) => ({
  maxWidth: 375,
  width: '100%',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 300,
  lineHeight: '16px',
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const EnvironmentGuard = ({ children }) => {
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);
  const navigate = useNavigate();
  const navigateToGeneralSettings = () => {
    navigate(`${PORTAL_ROUTE}/company/environments`);
  };

  if (customerEnvId) {
    return children;
  }

  return (
    <AlertPopup
      title={pagesText.noEnvironmentAlert.title}
      styles={{
        height: '450px',
      }}>
      <Description>
        {pagesText.noEnvironmentAlert.descriptionFirstPart}{' '}
        <Link
          component="button"
          variant="body2"
          onClick={navigateToGeneralSettings}
          sx={theme => ({
            fontWeight: 'bold',
            color: '#fff',
            textDecorationColor: '#fff',
            fontSize: theme.typography.pxToRem(16),
          })}>
          {pagesText.noEnvironmentAlert.descriptionLinkText}
        </Link>{' '}
        {pagesText.noEnvironmentAlert.descriptionSecondPart}
      </Description>
      <Description>{pagesText.appsStarterPopup.extraDescription}</Description>
    </AlertPopup>
  );
};

export default EnvironmentGuard;
