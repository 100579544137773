import { memo, useCallback, useMemo, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { CUSTOMER_QUERY_KEY } from 'src/constants/query';
import { customerIdSelector } from 'src/selectors/customer';
import TableContainer from 'src/components/Table/TableContainer';
import TableHeading from 'src/components/Table/TableHeading';
import LoadingRows from 'src/components/Table/LoadingRows';
import Row from 'src/components/Table/Row';
import NoDataCell from 'src/components/NoDataCell';
import useQueryData from 'src/queries/useQueryData';
import { logsHeadings, logsColumnsOrder, logscustomHeader } from './constants';
import { Box, IconButton, TableCell } from '@mui/material';
import DeviceService from 'src/services/device.service';
import { handleUrlDownload } from 'src/utils/download.util';
import GenericTableCell from 'src/components/GenericTableCell';
import { formatDate } from 'src/utils/formatTime';
import { formatBytes } from 'src/utils/helpers';
import Iconify from 'src/components/Iconify';
import LogFilterSidebar from './LogFilterSidebar';
import useQueryParams from 'src/hooks/useQueryParams';
import { checkHasFilters } from 'src/utils/table.util';
import { defaultDeviceLogsFilters } from 'src/components/Filters/constants';
import useTableSort from 'src/hooks/useTableSort';
import { useParams } from 'react-router-dom';
import useFetchLogs from 'src/pages/devices/deviceDetails/deviceLogs/useFetchLogs';
import FilterCount from 'src/components/Filters/FilterCount';

const DeviceLogsTable = () => {
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const customerId = useQueryData(CUSTOMER_QUERY_KEY, customerIdSelector);

  const { deviceId } = useParams();

  const {
    isLoading,
    data: logs,
    lastElementRef,
    isFetching,
    totalCount,
  } = useFetchLogs({ deviceId: deviceId });

  const {
    queryObject: { page, rowsPerPage, filters = {} },
    onApplySearchParams,
  } = useQueryParams({ defaultRowsPerPage: 5 });

  const { onSortTable, onResetSortField, getListItemSortMethod } = useTableSort({
    filters,
    onApplySearchParams,
    page,
    rowsPerPage,
    defaultFilters: defaultDeviceLogsFilters,
  });

  const onCloseFilters = useCallback(() => {
    setIsOpenFilters(false);
  }, [setIsOpenFilters]);

  const onOpenFilters = useCallback(() => {
    setIsOpenFilters(true);
  }, [setIsOpenFilters]);

  const onApplyFilters = useCallback(
    filters => {
      onApplySearchParams({ filters, page: 1, limit: rowsPerPage });
    },
    [onApplySearchParams, rowsPerPage],
  );

  const onLinkClick = useCallback(({ deviceId, logFileName, logFileType }, mode) => {
    DeviceService.getDevicesLogFile({
      deviceId,
      filename: logFileName,
      type: logFileType,
    }).then(urls => {
      handleUrlDownload(mode === 'download' ? urls?.downloadUrl : urls?.viewUrl);
    });
  }, []);

  const renderCustomCell = useCallback(
    (column, row) => {
      if (column === logscustomHeader.size) {
        return (
          <GenericTableCell
            key={row[column?.name || column]}
            value={formatBytes(row[column?.name || column])}
            styles={column?.styles || {}}
            tooltipText={column?.tooltipText}
          />
        );
      }

      if (column === logscustomHeader.modified) {
        return (
          <GenericTableCell
            key={row[column?.name || column]}
            value={formatDate(row[column?.name || column])}
            styles={column?.styles || {}}
            tooltipText={column?.tooltipText}
          />
        );
      }

      if (column === logscustomHeader.actions) {
        return (
          <TableCell>
            <Box display="flex" alignContent={'center'} gap={'4px'}>
              <IconButton
                sx={{ ml: '-5px' }}
                size="small"
                id="next-button"
                onClick={e => {
                  e.stopPropagation();
                  onLinkClick(row, 'download');
                }}>
                <Iconify icon="material-symbols:download" color="#fff" width={20} height={20} />
              </IconButton>
              <IconButton
                size="small"
                id="next-button"
                onClick={e => {
                  e.stopPropagation();
                  onLinkClick(row, 'view');
                }}>
                <Iconify icon="hugeicons:view" color="#fff" width={20} height={20} />
              </IconButton>
            </Box>
          </TableCell>
        );
      }

      return (
        <GenericTableCell
          key={row[column?.name || column]}
          value={row[column?.name || column]}
          styles={column?.styles || {}}
          tooltipText={column?.tooltipText}
        />
      );
    },
    [onLinkClick],
  );

  const hasFilters = useMemo(() => checkHasFilters(filters), [filters]);

  const filteredCount = useMemo(() => {
    return !isLoading && hasFilters ? totalCount : null;
  }, [totalCount, hasFilters, isLoading]);

  return (
    <Box sx={{ position: 'relative' }}>
      <FilterCount filteredCount={filteredCount} onOpenFilters={onOpenFilters} />

      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <TableContainer
          square
          component={Paper}
          elevation={0}
          styles={{
            maxHeight: 'calc(100vh - 384px)',
          }}>
          <Table stickyHeader aria-label="collapsible table">
            <TableHeading
              headings={logsHeadings}
              onOpenFilters={onOpenFilters}
              onSortTable={onSortTable}
              onResetSortField={onResetSortField}
              getListItemSortMethod={getListItemSortMethod}
              hasFilter={false}
              hasFilters={hasFilters}
              hasHeaderCorner
              listCellStyles={{
                top: '0px',
                padding: '10px 16px',
              }}
              filterTopPisition="0"
            />

            <TableBody sx={{ backgroundColor: '#4B4B4B' }}>
              {isLoading ? (
                <LoadingRows columnCount={5} rowCount={3} />
              ) : (
                <>
                  {!!logs?.length &&
                    logs.map((row, index) => (
                      <Row
                        withTwoColors={true}
                        hasActions={false}
                        ref={index === logs.length - 1 ? lastElementRef : null}
                        key={row?.id}
                        row={row}
                        columnData={logsColumnsOrder}
                        renderCustomCell={renderCustomCell}
                      />
                    ))}
                  {!isLoading && isFetching && <LoadingRows columnCount={5} rowCount={3} />}
                  {!isLoading && !logs?.length && !!customerId && (
                    <NoDataCell colSpan={5}>No log files yet.</NoDataCell>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <LogFilterSidebar
          open={isOpenFilters}
          onClose={onCloseFilters}
          onApplyFilters={onApplyFilters}
          initialQueryFilters={filters}
        />
      </Paper>
    </Box>
  );
};

export default memo(DeviceLogsTable);
