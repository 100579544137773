const { PORTAL_ROUTE } = require('src/constants/routes');

export const wdacResultsTabs = counts => [
  {
    label: `Win32 Apps (${counts?.win32AppsCount || 0})`,
    value: `${PORTAL_ROUTE}/wdac-results/win32-apps`,
  },
  {
    label: `Packaged Apps (${counts?.packedAppsCount || 0})`,
    value: `${PORTAL_ROUTE}/wdac-results/packaged-apps`,
  },
  {
    label: `Scripts (${counts?.scriptsCount || 0})`,
    value: `${PORTAL_ROUTE}/wdac-results/scripts`,
  },
];

export const wdacAppTabs = (appId, counts) => [
  {
    label: `Affected Binaries (${counts?.affectedBinariesCount || 0})`,
    value: `${PORTAL_ROUTE}/wdac-apps/${appId}/affected-binaries`,
  },
  {
    label: `Impacted Devices (${counts?.impactedDevicesCount || 0})`,
    value: `${PORTAL_ROUTE}/wdac-apps/${appId}/impacted-devices`,
  },
  {
    label: `Matched Applications (${counts?.matchedAppsCount || 0})`,
    value: `${PORTAL_ROUTE}/wdac-apps/${appId}/matched-applications`,
  },
];
