import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import TabsPageLayout from 'src/components/TabsPageLayout';
import { RESULTS_TAB_DATA_COUNTS_KEY, WDAC_APP_QUERY_KEY } from 'src/constants/query';
import { PORTAL_ROUTE } from 'src/constants/routes';
import { wdacAppTabs } from 'src/pages/wdac-results/constants';
import WDACResultsService from 'src/services/wdac-results.service';
import { WDAC_APP_PREVIOUS_PATH } from '../constants';
import useSubscribeStorage from 'src/hooks/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';
import { Loader } from 'src/components';

const WDACAppTabs = () => {
  const { appId, isLoading } = useParams();
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);

  const { data: app } = useQuery([WDAC_APP_QUERY_KEY, appId], () =>
    WDACResultsService.getWDACApp({ globalApplicationVersionId: appId }),
  );

  const { data: counts, isLoading: countsIsLoading } = useQuery(
    [RESULTS_TAB_DATA_COUNTS_KEY, appId],
    () =>
      WDACResultsService.getAppWdacTabsDataCounts({
        globalApplicationVersionId: appId,
        customerEnvId,
      }),
    { enabled: !!appId },
  );

  const backNavigationPath = useMemo(() => {
    const storedPath = sessionStorage.getItem(WDAC_APP_PREVIOUS_PATH);

    if (!storedPath) {
      return `${PORTAL_ROUTE}/wdac-results/win32-apps`;
    }

    return storedPath;
  }, []);

  if (isLoading || countsIsLoading) {
    return <Loader />;
  }

  return (
    <TabsPageLayout
      hasBackButton
      title={app?.name || ''}
      tabs={wdacAppTabs(appId, counts)}
      backNavigationPath={backNavigationPath}
      wrapperProps={{
        sx: {
          maxWidth: '100%',
        },
      }}
      titleProps={{
        fontSize: '1.4rem',
      }}
    />
  );
};

export default WDACAppTabs;
