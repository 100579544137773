import { Stack } from '@mui/material';
import { colors } from 'src/theme/palette/darkPalette';
import Iconify from '../Iconify';

const FilterCount = ({ filteredCount, onOpenFilters, onlyView = false }) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      color={!!filteredCount ? colors.green : colors.white}
      sx={{
        cursor: onlyView ? 'unset' : 'pointer',
        backgroundColor: '#4B4B4B',
        width: 'fit-content',
        minHeight: '24px',
        ml: 'auto',
        p: '0 8px',
        minWidth: '80px',
        borderRadius: 1,
        fontSize: '14px',
        position: 'absolute',
        top: '-28px',
        right: 0,
        fontWeight: 600,
      }}
      onClick={onOpenFilters}
      gap={1}>
      {!!filteredCount ? `Filtered (${filteredCount})` : 'Filter'}{' '}
      <Iconify
        onClick={onOpenFilters}
        icon="octicon:filter-16"
        color={!!filteredCount ? colors.green : colors.white}
        width={16}
        height={16}
        sx={{
          cursor: onlyView ? 'unset' : 'pointer',
        }}
      />
    </Stack>
  );
};

export default FilterCount;
