export const detailsHeadings = [
  {
    title: 'Application Name',
    name: 'arpDisplayName',
    hasSorting: false,
    width: '33%',
  },
  {
    title: 'App Version',
    name: 'arpVersion',
    hasSorting: false,
    width: '33%',
  },
  {
    title: 'Publisher',
    name: 'arpPublisher',
    hasSorting: false,
    width: '33%',
  },
];

export const columnsOrderDetails = [
  { name: 'arpDisplayName', styles: { wordBreak: 'break-word' } },
  { name: 'arpVersion' },
  { name: 'arpPublisher' },
];
