import React, { useState, useMemo } from 'react';
import { TableRow, TableCell, Collapse } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';

import Iconify from '../../../../components/Iconify';
import UnmatchedFileItemTable from './UnmatchedFileItemTable';
import useQueryParams from '../../../../hooks/useQueryParams';
import { highlightText } from 'src/utils/helpers';
import { formatDate } from 'src/utils/formatTime';
import { TableCellTypography } from 'src/components/GenericTableCell';
import { tableRowColor } from 'src/constants/table';

const UnmatchedFilesAccordion = ({ log, index, lastElementRef, logs, deviceId }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(prev => !prev);
  };

  const {
    queryObject: { searchValue },
  } = useQueryParams();

  const highlightedHashText = useMemo(
    () => highlightText(log.authenticodeHashSha256, searchValue),
    [log.authenticodeHashSha256, searchValue],
  );

  const highlightedPathText = useMemo(
    () => highlightText(log.fullFilePath, searchValue),
    [log.fullFilePath, searchValue],
  );

  return (
    <>
      <TableRow
        key={log.id}
        ref={index === logs.length - 1 ? lastElementRef : null}
        onClick={handleToggle}
        sx={{
          '&:nth-of-type(4n+3)': {
            backgroundColor: tableRowColor,
          },
          backgroundColor: '#4B4B4B',
          cursor: 'pointer',
        }}>
        <TableCell>
          <TableCellTypography dangerouslySetInnerHTML={{ __html: highlightedPathText }} />
        </TableCell>
        <TableCell>
          <TableCellTypography dangerouslySetInnerHTML={{ __html: highlightedHashText }} />
        </TableCell>
        <TableCell>
          <TableCellTypography>
            {log.eventSystemTime ? formatDate(log.eventSystemTime) : '-'}
          </TableCellTypography>
        </TableCell>
        <TableCell>
          <TableCellTypography>{log.itemCount}</TableCellTypography>
        </TableCell>
        <TableCell align="right">
          <Iconify
            icon={expanded ? 'iconamoon:arrow-up-2-duotone' : 'iconamoon:arrow-down-2-duotone'}
            color="#fff"
            width={20}
            height={20}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5} sx={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <AccordionDetails>
              <UnmatchedFileItemTable
                deviceId={deviceId}
                authenticodeHashSha256={log.authenticodeHashSha256}
                isOpen={expanded}
              />
            </AccordionDetails>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UnmatchedFilesAccordion;
