import CustomCell from 'src/components/Table/CustomCell';
import { PORTAL_ROUTE } from 'src/constants/routes';
import { sortingMethods } from 'src/constants/table';

export const defaultFilterUnmatchedFiles = {
  FilterUnmatchedFiles: [
    [
      {
        name: 'Name',
        type: 'date',
        value: '',
        label: 'Name',
      },
    ],
  ],
};

export const columnsOrder = [
  { name: 'processName', styles: { wordBreak: 'break-word' } },
  { name: 'fullFilePath', styles: { wordBreak: 'break-word' } },
  { name: 'deviceWdacEventsCount', isNumeric: true },
  {
    name: 'eventSystemTime',
    styles: { minWidth: '70px' },
  },
];

export const headings = [
  {
    title: 'Process Name',
    name: 'processName',
    hasSorting: false,
    width: '35%',
  },
  {
    title: 'File Name',
    name: 'fullFilePath',
    hasSorting: false,
    width: '35%',
  },
  {
    title: 'WDAC Events Count',
    name: 'deviceWdacEventsCount',
    hasSorting: false,
    width: '15%',
  },
  {
    title: 'Date',
    name: 'eventSystemTime',
    hasSorting: false,
    width: '15%',
  },
];

export const deviceTabs = (deviceId, counts) => [
  {
    label: `General`,
    value: `${PORTAL_ROUTE}/devices/${deviceId}/general`,
  },
  {
    label: `Win32 Apps (${counts?.win32AppsCount || 0})`,
    value: `${PORTAL_ROUTE}/devices/${deviceId}/win32-apps`,
  },
  {
    label: `Unmatched Files (${counts?.unmatchedFilesCount || 0})`,
    value: `${PORTAL_ROUTE}/devices/${deviceId}/unmatched-files`,
  },
  {
    label: `Logs (${counts?.logsCount || 0})`,
    value: `${PORTAL_ROUTE}/devices/${deviceId}/logs`,
  },
];

export const MAIN_TABLE_HEADERS = [
  { title: 'File Name', name: 'filePath', width: '26%' },
  { title: 'Authenticode Hash Sha256', name: 'authenticodeHash', width: '40%' },
  {
    title: 'Event System Time',
    name: 'eventSystemTime',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterUnmatchedFiles',
    sortingCellField: 'event_system_time',
    sortFieldName: 'OrderWdacQuery',
    width: '18%',
  },
  {
    title: 'Count',
    name: 'count',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterUnmatchedFiles',
    sortingCellField: 'wdac_items_total_count',
    sortFieldName: 'OrderWdacQuery',
    width: '8%',
  },
  { title: '', name: 'actions', width: '8%' },
];
