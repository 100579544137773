import { useCallback, useMemo } from 'react';
import useInfiniteFetch from '../../../../queries/useInfiniteFetch';
import useSubscribeStorage from '../../../../hooks/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';
import { DEVICE_UNMATCHED_FILES_QUERY_KEY } from 'src/constants/query';
import DeviceService from 'src/services/device.service';
import { EXPANDED_ROWS_LIMIT } from 'src/constants/table';
import useQueryParams from 'src/hooks/useQueryParams';
import { parseQueryToApiQuery } from 'src/utils/query.utils';

const useFetchUnmatchedFiles = ({ deviceId }) => {
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);

  const {
    queryObject: { filters = {}, searchValue: initialSearchValue, ...rest },
  } = useQueryParams();

  const query = useMemo(
    () => parseQueryToApiQuery({ filters, searchValue: initialSearchValue, ...rest }),
    [filters, initialSearchValue, rest],
  );

  const fetchFunction = useCallback(
    ({ page, limit }) =>
      DeviceService.getDeviceUnmatchedFilesWdacResults({
        limit,
        page,
        deviceId,
        customerEnvId,
        query,
      }),
    [deviceId, customerEnvId, query],
  );
  const response = useInfiniteFetch({
    queryKey: [DEVICE_UNMATCHED_FILES_QUERY_KEY, query, customerEnvId, deviceId],
    fetchFunction,
    expandedRowsLimit: EXPANDED_ROWS_LIMIT,
    itemName: 'results',
    noCacheTime: true,
  });

  return {
    ...response,
  };
};

export default useFetchUnmatchedFiles;
