import { useCallback, useMemo } from 'react';
import useInfiniteFetch from '../../../../queries/useInfiniteFetch';
import useSubscribeStorage from '../../../../hooks/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';
import { DEVICE_LOGS_QUERY_KEY } from 'src/constants/query';
import DeviceService from 'src/services/device.service';
import { EXPANDED_ROWS_LIMIT } from 'src/constants/table';
import useQueryParams from '../../../../hooks/useQueryParams';
import { parseQueryToApiQuery } from 'src/utils/query.utils';

const useFetchLogs = ({ deviceId, hasQueryParams = true }) => {
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);

  const {
    queryObject: { filters = {} },
  } = useQueryParams();

  const query = useMemo(
    () =>
      parseQueryToApiQuery({
        filters,
      }),
    [filters],
  );

  const fetchFunction = useCallback(
    ({ page, limit }) =>
      DeviceService.getDevicesLogs({
        limit,
        page,
        deviceId,
        query,
      }),
    [deviceId, query],
  );
  const response = useInfiniteFetch({
    queryKey: hasQueryParams
      ? [DEVICE_LOGS_QUERY_KEY, query, customerEnvId, deviceId]
      : [DEVICE_LOGS_QUERY_KEY, customerEnvId, deviceId],
    fetchFunction,
    expandedRowsLimit: EXPANDED_ROWS_LIMIT,
    itemName: 'logFiles',
  });

  return {
    ...response,
    query,
  };
};

export default useFetchLogs;
