import { useEffect, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useInView } from 'react-intersection-observer';
import useQueryParams from '../hooks/useQueryParams';

const refetchOnMountDefaultValue = 'always';

const useInfiniteFetch = ({
  queryKey,
  fetchFunction,
  expandedRowsLimit,
  itemName,
  enabled = true,
  searchValue: inputSearchValue,
  refetchOnMount = refetchOnMountDefaultValue,
  noCacheTime = false,
}) => {
  const { ref: lastElementRef, inView } = useInView();
  const {
    queryObject: { page, limit, ...rest },
  } = useQueryParams();

  const defaultPage = page || 1;
  const defaultLimit = limit || expandedRowsLimit;

  const query = useMemo(() => {
    return {
      ...rest,
    };
  }, [rest]);

  const { data, hasNextPage, fetchNextPage, isLoading, ...props } = useInfiniteQuery(
    inputSearchValue ? [queryKey, inputSearchValue] : [queryKey],
    async ({ pageParam = defaultPage }) => {
      return fetchFunction({
        page: pageParam,
        limit: defaultLimit,
        ...(inputSearchValue ? { search: inputSearchValue } : {}),
        ...query,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage[itemName]?.length >= defaultLimit) {
          return allPages.length + 1;
        }
        return undefined;
      },
      enabled,
      refetchOnMount,
      ...(noCacheTime ? { cacheTime: 0 } : {}),
    },
  );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const mergedData = useMemo(() => {
    if (!data?.pages?.length) {
      return {
        data: [],
        count: 0,
      };
    }

    const combined = data.pages.reduce(
      (acc, page) => {
        acc.data = [...acc.data, ...page[itemName]];
        acc.count = Math.max(acc.count, page.count || 0);
        if (page.totalCount) {
          acc.totalCount = page.totalCount;
        }
        return acc;
      },
      { data: [], count: 0 },
    );

    const lastPage = data.pages[data.pages.length - 1];
    return {
      ...lastPage,
      ...combined,
    };
  }, [data, itemName]);

  return {
    ...mergedData,
    lastElementRef,
    hasNextPage,
    isLoading,
    ...props,
  };
};

export default useInfiniteFetch;
