import { memo, useCallback } from 'react';
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TableAppImage from './TableAppImage';
import Skeleton from '../Skeleton';
import useQueryParams from '../../hooks/useQueryParams';

const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: prop => prop !== 'isRowActive',
})(({ theme, isRowActive }) => ({
  [`& .${listItemTextClasses.primary}`]: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '22px',
    color: isRowActive ? '#000' : '#fff',
  },
  [`& .${listItemTextClasses.secondary}`]: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '22px',
    color: '#DDDDDD',
  },
  '& .highlight': {
    backgroundColor: '#7DFF92',
    color: 'black',
    fontWeight: 'bold',
    padding: '0 2px',
  },
  maxWidth: 400,
}));

const TableNameCell = ({ name, imgSrc, version, loading, isRowActive, ...props }) => {
  const {
    queryObject: { searchValue },
  } = useQueryParams();

  const highlightText = useCallback(
    (text) => {
      if (!searchValue || typeof text !== 'string') return text;
      const regex = new RegExp(`(${searchValue})`, 'gi');
      return text.replace(regex, `<span class="highlight">$1</span>`);
    },
    [searchValue],
  );

  return (
    <TableCell
      {...props}
      scope="row"
      sx={{
        paddingRight: 0,
        color: 'inherit',
        ...props.sx,
      }}>
      <Stack direction="row" gap={2} alignItems="center">
        <TableAppImage name={name} imgSrc={imgSrc} loading={loading} />
        {loading ? (
          <Skeleton variant="text" animation="pulse" width="100%" height={22} />
        ) : (
          <StyledListItemText
            primary={
              <Typography
                component="span"
                dangerouslySetInnerHTML={{ __html: highlightText(name) }}
              />
            }
            secondary={
              version ? (
                <Typography
                  component="span"
                  dangerouslySetInnerHTML={{ __html: highlightText(version) }}
                />
              ) : null
            }
            isRowActive={isRowActive}
          />
        )}
      </Stack>
    </TableCell>
  );
};

export default memo(TableNameCell);
