import { useCallback, useMemo } from 'react';
import { parseQueryToApiQuery } from '../utils/query.utils';
import DeviceService from '../services/device.service';
import useInfiniteFetch from './useInfiniteFetch';
import { DEVICES_QUERY_KEY } from '../constants/query';
import useQueryParams from '../hooks/useQueryParams';
import { EXPANDED_ROWS_LIMIT } from '../constants/table';
import useSubscribeStorage from '../hooks/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';

const useFetchDevices = ({ hasQueryParams = true }) => {
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);
  const {
    queryObject: { filters = {}, searchValue: initialSearchValue },
  } = useQueryParams();

  const query = useMemo(
    () =>
      parseQueryToApiQuery({
        filters,
        searchValue: initialSearchValue,
      }),
    [filters, initialSearchValue],
  );

  const fetchFunction = useCallback(
    ({ page, limit }) =>
      DeviceService.getDevices({
        limit,
        page,
        customerEnvId,
        query,
      }),
    [customerEnvId, query],
  );

  const response = useInfiniteFetch({
    queryKey: hasQueryParams
      ? [DEVICES_QUERY_KEY, query, customerEnvId]
      : [DEVICES_QUERY_KEY, '', customerEnvId],
    fetchFunction,
    expandedRowsLimit: EXPANDED_ROWS_LIMIT,
    itemName: 'devices',
    refetchOnMount: false,
  });

  return {
    ...response,
    query,
  };
};

export default useFetchDevices;
