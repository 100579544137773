export const detailsHeadings = [
  {
    title: 'Device Name',
    name: 'name',
    hasSorting: false,
    width: '33%',
  },
  {
    title: 'Enforcement Mode',
    name: 'enforcementMode',
    hasSorting: false,
    width: '33%',
  },
  {
    title: 'Last Event System Time',
    name: 'lastEventSystemTime',
    hasSorting: false,
    width: '33%',
  },
];

export const columnsOrderDetails = [
  { name: 'name', styles: { wordBreak: 'break-word' } },
  { name: 'enforcementMode', styles: { wordBreak: 'break-word' } },
  { name: 'lastEventSystemTime', styles: { minWidth: '90px' } },
];
