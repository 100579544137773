import React, { useCallback, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from 'src/components/Table/TableContainer';
import { columnsOrderDetails, detailsHeadings } from './constants';
import TableHeading from 'src/components/Table/TableHeading';
import { Box, TableBody } from '@mui/material';
import NoDataCell from 'src/components/NoDataCell';
import { pagesText } from 'src/constants/pagesText';
import Row from 'src/components/Table/Row';
import LoadingRows from 'src/components/Table/LoadingRows';
import { useParams } from 'react-router-dom';
import useGetAppImpacredDevices from './useGetAppImpacredDevices';
import useQueryParams from 'src/hooks/useQueryParams';
import useDebounceHandler from 'src/hooks/useDebounceHandler';
import { defaultSearchParams, ROW_SOURCES } from 'src/constants/table';
import TableSearch from 'src/components/elements/TableSearch';

const ImpactedDevicesTab = () => {
  const { appId } = useParams();

  const {
    queryObject: { searchValue: initialSearchValue },
    changeFieldInURL,
  } = useQueryParams();

  const [searchValue, setSearchValue] = useState(initialSearchValue);

  const debounceQueryHandler = useDebounceHandler((key, value) => {
    changeFieldInURL([{ key, value }]);
  }, 500);

  const onChangeSearchValue = useCallback(
    event => {
      setSearchValue(event.target.value);
      debounceQueryHandler('search', event.target.value);
    },
    [debounceQueryHandler],
  );

  const onResetSearch = useCallback(() => {
    setSearchValue('');
    changeFieldInURL([defaultSearchParams]);
  }, [setSearchValue, changeFieldInURL]);

  const { isLoading, data, lastElementRef, isFetching } = useGetAppImpacredDevices({
    appId,
  });

  return (
    <Box>
      <TableSearch
        searchValue={searchValue}
        onChangeSearchValue={onChangeSearchValue}
        onResetSearch={onResetSearch}
      />
      <TableContainer
        square
        component={Paper}
        elevation={0}
        styles={{ maxHeight: `calc(100vh - 368px)` }}>
        <Table stickyHeader>
          <TableHeading
            headings={detailsHeadings}
            listCellStyles={{
              top: '0px',
            }}
            hasFilter={false}
          />
          <TableBody>
            {!isLoading && !isFetching && !data?.length && (
              <NoDataCell colSpan={6}>{pagesText.noDataFound}</NoDataCell>
            )}
            {!!data?.length &&
              data.map((row, index) => (
                <Row
                  withTwoColors={true}
                  ref={index === data.length - 1 ? lastElementRef : null}
                  key={index}
                  row={row}
                  columnData={columnsOrderDetails}
                  hasActions={false}
                  highlightText={true}
                  source={ROW_SOURCES.devices}
                  isClickable={true}
                />
              ))}
          </TableBody>
          {isLoading || isFetching ? <LoadingRows columnCount={3} rowCount={3} /> : null}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ImpactedDevicesTab;
