import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { isObjectEmpty } from '../utils/boolean.util';
import { sortFieldsNameMap } from '../components/Filters/constants';
import { reversedSortMap, sortingMethods } from 'src/constants/table';

const useTableSort = ({ filters, onApplySearchParams, searchValue, defaultFilters }) => {
  const { enqueueSnackbar } = useSnackbar();

  const resetFieldsSort = useCallback(
    fieldName => {
      let newFilters = { ...(isObjectEmpty(filters) ? defaultFilters : filters) };
      const mappedFieldName = sortFieldsNameMap[fieldName] || fieldName;

      Object.keys(newFilters).forEach(filterField => {
        if (filterField !== mappedFieldName) {
          newFilters[filterField] = newFilters[filterField].map(singleFilterSection =>
            singleFilterSection.map(singleFilterItem => {
              delete singleFilterItem.order;
              return singleFilterItem;
            }),
          );
        }
      });

      return newFilters;
    },
    [defaultFilters, filters],
  );

  const onSort = useCallback(
    (fieldName, sortMethod, sortingCellField, sortFieldName) => {
      const mappedFieldName = sortFieldsNameMap[fieldName] || fieldName;

      try {
        const newFilters = resetFieldsSort(mappedFieldName);
        newFilters[mappedFieldName] = newFilters[mappedFieldName].map(
          (singleFilterSection, index) => {
            if (index === 0) {
              return singleFilterSection.map((singleFilterItem, index) => {
                if (index === 0) {
                  return {
                    ...singleFilterItem,
                    order: sortMethod,
                    sortingCellField,
                    sortFieldName,
                  };
                }

                return singleFilterItem;
              });
            }
            return singleFilterSection;
          },
        );

        onApplySearchParams({ filters: newFilters, searchValue });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [onApplySearchParams, searchValue, enqueueSnackbar, resetFieldsSort],
  );

  const onResetSortField = useCallback(
    name => {
      try {
        const mappedFieldName = sortFieldsNameMap[name] || name;
        const filtersWithoutSort = { ...filters };
        filtersWithoutSort[mappedFieldName] = filtersWithoutSort[mappedFieldName].map(value => {
          return value.map(filterItem => {
            if (filterItem.order) {
              delete filterItem.order;
            }
            if (filterItem.sortingCellField) {
              delete filterItem.sortingCellField;
            }
            return filterItem;
          });
        });

        onApplySearchParams({ filters: filtersWithoutSort, searchValue });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, filters, onApplySearchParams, searchValue],
  );

  const getListItemSortMethod = useCallback(
    heading => {
      const mappedFieldName = sortFieldsNameMap[heading] || heading;
      if (filters[mappedFieldName]) {
        const filter = filters[mappedFieldName][0].find(filter => filter.order);
        if (filter) return filter.order;
      }

      return sortingMethods.initial;
    },
    [filters],
  );

  const getCellSortMethod = useCallback(
    (heading, sortingCellField) => {
      if (filters[heading]) {
        const field = filters[heading][0].find(filter => filter.order);
        return field?.sortingCellField === sortingCellField ? field.order : sortingMethods.initial;
      }

      return sortingMethods.initial;
    },
    [filters],
  );

  const onSortTable = useCallback(
    (sortMethod, heading) => {
      const reversedSortOrder =
        sortMethod === sortingMethods.initial ? sortingMethods.asc : reversedSortMap[sortMethod];
      onSort(heading, reversedSortOrder);
    },
    [onSort],
  );

  return {
    onSort,
    onSortTable,
    onResetSortField,
    getListItemSortMethod,
    getCellSortMethod,
  };
};

export default useTableSort;
