import React, { useEffect, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHeading from '../../../../components/Table/TableHeading';
import TableBody from '@mui/material/TableBody';
import Row from '../../../../components/Table/Row';
import TableContainer from '../../../../components/Table/TableContainer';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import {
  APPLICATION_VERSIONS_QUERY_KEY,
  UNMATCHED_DEVICE_ITEMS,
} from '../../../../constants/query';
import deviceService from '../../../../services/device.service';
import { EXPANDED_ROWS_LIMIT } from '../../../../constants/table';
import { columnsOrder, headings } from './constants';
import useSubscribeStorage from '../../../../hooks/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from '../../../../constants/storage';
import LoadingRows from '../../../../components/Table/LoadingRows';
import NoDataCell from '../../../../components/NoDataCell';
import { pagesText } from '../../../../constants/pagesText';

const UnmatchedFileItemTable = ({ deviceId, authenticodeHashSha256, isOpen }) => {
  const { ref: lastElementRef, inView } = useInView();
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);
  const queryClient = useQueryClient();

  const { data, error, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery(
      [UNMATCHED_DEVICE_ITEMS, customerEnvId, deviceId, authenticodeHashSha256],
      async ({ pageParam = 1 }) =>
        await deviceService.getDeviceUnmatchedFilesWdacResultItems({
          deviceId: deviceId,
          customerEnvId,
          hashCode: authenticodeHashSha256,
          page: 1,
          limit: (pageParam - 1) * EXPANDED_ROWS_LIMIT + EXPANDED_ROWS_LIMIT,
        }),
      {
        enabled: isOpen,
        getNextPageParam: (lastPage, allPages) => {
          const totalCount = lastPage?.totalCount;
          const fetchedCount = lastPage.results.length || 0;
          return fetchedCount < totalCount ? allPages.length + 1 : undefined;
        },
      },
    );

  const devicesRowData = useMemo(
    () => ({
      devices: data?.pages[data.pages.length - 1]?.results || [],
      count: data?.pages[0].totalCount || 0,
    }),
    [data],
  );

  const renderNoDataCell = useMemo(() => {
    return error || devicesRowData.devices?.length === 0;
  }, [error, devicesRowData]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries([
        APPLICATION_VERSIONS_QUERY_KEY,
        customerEnvId,
        deviceId,
        authenticodeHashSha256,
      ]);
    };
  }, [queryClient, customerEnvId, deviceId, authenticodeHashSha256]);

  return (
    <>
      <TableContainer square component={Paper} elevation={0}>
        <Table stickyHeader>
          <TableHeading
            headings={headings}
            listCellStyles={{
              top: '0px',
            }}
            hasFilter={false}
          />
          <TableBody>
            {!isLoading && renderNoDataCell ? (
              <NoDataCell>{pagesText.noDataFound}</NoDataCell>
            ) : null}
            {devicesRowData.devices.map((item, index) => (
              <Row
                withTwoColors={true}
                ref={index === devicesRowData.devices.length - 1 ? lastElementRef : null}
                key={index}
                row={{ ...item, deviceId }}
                columnData={columnsOrder}
                hasActions={false}
                highlightText={false}
              />
            ))}
          </TableBody>
          {isLoading || isFetchingNextPage ? <LoadingRows columnCount={4} rowCount={1} /> : null}
        </Table>
      </TableContainer>
    </>
  );
};

export default UnmatchedFileItemTable;
