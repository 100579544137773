import { useParams } from 'react-router-dom';
import TabsPageLayout from '../../../components/TabsPageLayout';
import { PORTAL_ROUTE } from '../../../constants/routes';
import useDevice from '../../../queries/deviceDetails/useDevice';
import DeviceService from 'src/services/device.service';
import { deviceTabs } from './unmatchedFiles/constants';
import useSubscribeStorage from 'src/hooks/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';
import { useQuery } from 'react-query';
import { DEVICE_TABS_DATA_COUNTS_KEY } from 'src/constants/query';
import { Loader } from 'src/components';

const AppDetails = () => {
  const { deviceId } = useParams();
  const { data: device } = useDevice({ deviceId });
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);

  const { data: counts, isLoading: countsIsLoading } = useQuery(
    [DEVICE_TABS_DATA_COUNTS_KEY, deviceId],
    () =>
      DeviceService.getDeviceDetailsTabsDataCounts({
        customerEnvId,
        deviceId: deviceId,
      }),
    { enabled: !!deviceId },
  );

  if (countsIsLoading) {
    return <Loader />;
  }

  return (
    <TabsPageLayout
      hasBackButton
      title={device?.device?.name}
      tabs={deviceTabs(deviceId, counts)}
      backNavigationPath={`${PORTAL_ROUTE}/devices`}
      wrapperProps={{
        sx: {
          maxWidth: '100%',
        },
      }}
      titleProps={{
        fontSize: '1.4rem',
      }}
    />
  );
};

export default AppDetails;
