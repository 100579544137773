import CustomCell from 'src/components/Table/CustomCell';
import { sortingMethods } from 'src/constants/table';

export const defaultAppresultFilters = {
  FilterAppResult: [
    [
      {
        name: 'Name',
        type: 'date',
        value: '',
        label: 'Name',
      },
    ],
  ],
};

export const topHeadingDevice = [
  {
    title: 'Application details',
    colSpan: 3,
    borderRight: true,
  },
  {
    title: 'Launch Attempts',
    colSpan: 4,
    borderRight: true,
  },
  {
    title: '',
    colSpan: 1,
  },
];

export const secondHeadingsDevice = [
  {
    title: 'Name',
    name: 'arpDisplayName',
    hasSorting: true,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'arp_display_name',
    sortFieldName: 'OrderWdacQuery',
    width: '16%',
  },
  {
    title: 'Version',
    name: 'arpDisplayVersion',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'arp_display_version',
    sortFieldName: 'OrderWdacQuery',
    width: '14%',
  },
  {
    title: 'Publisher',
    name: 'arpPublisher',
    borderRight: true,
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'arp_publisher',
    sortFieldName: 'OrderWdacQuery',
    width: '16%',
  },
  {
    title: '24 Hours',
    name: 'estimatedLaunchAttemptsLast24Hours',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'estimated_launch_attempts_last24hours',
    sortFieldName: 'OrderWdacQuery',
    width: '8%',
  },
  {
    title: '30 Days',
    name: 'estimatedLaunchAttemptsLast30Days',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'estimated_launch_attempts_last30days',
    sortFieldName: 'OrderWdacQuery',
    width: '8%',
  },
  {
    title: '90 Days',
    name: 'estimatedLaunchAttemptsLast90Days',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'estimated_launch_attempts_last90days',
    sortFieldName: 'OrderWdacQuery',
    width: '8%',
  },
  {
    title: 'Last launch time',
    name: 'lastLaunchTime',
    borderRight: true,
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'last_launch_time',
    sortFieldName: 'OrderWdacQuery',
    width: '14%',
  },
  {
    title: 'Matched apps in current environment',
    name: 'managedApplicationMatchCount',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'managed_application_match_count',
    sortFieldName: 'OrderWdacQuery',
    width: '16%',
  },
];

export const columnsOrderDevice = [
  { name: 'arpDisplayName' },
  { name: 'arpDisplayVersion' },
  { name: 'arpPublisher' },
  { name: 'estimatedLaunchAttemptsLast24Hours', isNumber: true },
  { name: 'estimatedLaunchAttemptsLast30Days', isNumber: true },
  { name: 'estimatedLaunchAttemptsLast90Days', isNumber: true },
  { name: 'lastLaunchTime' },
  { name: 'managedApplicationMatchCount', isNumber: true },
];
