import { pagesText } from '../../constants/pagesText';
import CustomCell from '../../components/Table/CustomCell';
import { sortingMethods } from 'src/constants/table';

export const headings = [
  {
    title: pagesText.devices.headings.name,
    name: 'name',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterDevice',
    sortingCellField: 'Name',
    sortFieldName: 'OrderDeviceQuery',
    width: '14%',
  },
  {
    title: pagesText.devices.headings.tagName,
    name: 'tagName',
    hasSorting: false,
    width: '18%',
  },
  {
    title: pagesText.devices.headings.lastContactDate,
    name: 'lastContactDate',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterDevice',
    sortingCellField: 'LastContactDate',
    sortFieldName: 'OrderDeviceQuery',
    width: '10%',
  },
  {
    title: pagesText.devices.headings.wdacStatus,
    name: 'wdacDeviceStatus',
    hasSorting: false,
    width: '10%',
  },
  {
    title: pagesText.devices.headings.osVersion,
    name: 'osVersion',
    hasSorting: false,
    width: '10%',
  },
  {
    title: pagesText.devices.headings.osSku,
    name: 'osSku',
    hasSorting: false,
    width: '10%',
  },
  {
    title: pagesText.devices.headings.osType,
    name: 'osType',
    hasSorting: false,
    width: '11%',
  },
  {
    title: pagesText.devices.headings.logCollectorVersion,
    name: 'logCollectorVersion',
    hasSorting: false,
    width: '8%',
  },
  {
    title: pagesText.devices.headings.status,
    name: 'status',
    hasSorting: false,
    width: '8',
  },
];

export const deviceStatus = {
  approved: 'approved',
  revoked: 'revoked',
  unknown: 'unknown',
};

export const deviceStatuses = [
  {
    label: 'Approved',
    value: deviceStatus.approved,
  },
  {
    label: 'Revoked',
    value: deviceStatus.revoked,
  },
  {
    label: 'Unknown',
    value: deviceStatus.unknown,
  },
];

export const columnsOrder = [
  'name',
  'tagName',
  'lastContactDate',
  'wdacDeviceStatus',
  'osVersion',
  'osSku',
  'osType',
  'logCollectorVersion',
  'status',
];

export const VISIBLE_DEVICES_COUNT = 5;
