import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { subDays } from 'date-fns';
import Typography from '@mui/material/Typography';
import {
  DEVICES_DASHBOARD_ENFORCEMENT_QUERY_KEY,
  DEVICES_DASHBOARD_HEALTH_QUERY_KEY,
  ENVIRONMENT_NAME_QUERY_KEY,
} from '../../constants/query';
import DeviceService from '../../services/device.service';
import { DeviceStatus, reportedDevicesLabelToValue, reportedDevicesMap } from './constants';
import { pagesText } from '../../constants/pagesText';
import ApplicationsService from '../../services/applications.service';
import { PORTAL_ROUTE } from '../../constants/routes';
import {
  getDeviceWdacStatusFilerQuery,
  getDeviceHealthStatusFilterQuery,
} from '../../utils/devices.util';
import DeviceChart from './DeviceChart';
import useQueryParams from '../../hooks/useQueryParams';
import DevicesChartTagFilter from './DevicesChartTagFilter';
import DatePicker from '../../components/DatePicker';
import useSubscribeStorage from 'src/hooks/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';

const DevicesCharts = () => {
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);
  const { queryObject, onApplySearchParams } = useQueryParams();

  const { startDate, endDate } = useMemo(() => {
    if (!queryObject?.deviceDashboardFilters?.length) {
      return {
        startDate: subDays(new Date(), 4).toISOString(),
        endDate: new Date().toISOString(),
      };
    }

    const selectedStartDate = queryObject?.deviceDashboardFilters[0];
    let selectedEndDate = queryObject?.deviceDashboardFilters[1];

    if (
      !selectedEndDate ||
      new Date(selectedEndDate).getTime() < new Date(selectedStartDate).getTime()
    ) {
      selectedEndDate = selectedStartDate;
    }

    return {
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    };
  }, [queryObject?.deviceDashboardFilters]);

  const tagNames = queryObject?.tagNamesFilters;
  const tagIds = useMemo(
    () => (queryObject?.tagIds ? JSON.parse(queryObject?.tagIds) : []),
    [queryObject?.tagIds],
  );
  const { data: devicesEnforcementData, isLoading: enforcementIsLoading } = useQuery(
    [DEVICES_DASHBOARD_ENFORCEMENT_QUERY_KEY, customerEnvId, startDate, endDate, tagNames],
    () =>
      DeviceService.getDeviceDashboardEnforcement({
        TagIds: tagIds,
        customerEnvId,
        startDate,
        endDate,
      }),
  );

  const { data: devicesHealthData, isLoading: healthIsLoading } = useQuery(
    [DEVICES_DASHBOARD_HEALTH_QUERY_KEY, customerEnvId, startDate, endDate, tagNames],
    () =>
      DeviceService.getDeviceDashboardHealth({
        TagIds: tagIds,
        customerEnvId,
        startDate,
        endDate,
      }),
  );

  const navigate = useNavigate();

  const enforcementStatusChartData = useMemo(() => {
    if (!devicesEnforcementData?.length) return [];
    const hasAnyDeviceCount = devicesEnforcementData.some(
      enforcementStatus => !!enforcementStatus.deviceCount,
    );
    if (!hasAnyDeviceCount) return [];

    return devicesEnforcementData.map(enforcementStatus => ({
      label: enforcementStatus.status,
      value: enforcementStatus.deviceCount,
      name: enforcementStatus.status,
      enforcementStatus,
    }));
  }, [devicesEnforcementData]);

  const deviceHealthChartData = useMemo(() => {
    if (!devicesHealthData?.length) return [];
    const hasAnyDeviceCount = devicesHealthData.some(deviceHealth => !!deviceHealth.deviceCount);
    if (!hasAnyDeviceCount) return [];

    return devicesHealthData.flatMap(deviceHealth =>
      deviceHealth.deviceCount !== 0
        ? [
            {
              label: reportedDevicesMap[deviceHealth.healthStatus],
              value: deviceHealth.deviceCount,
              name: reportedDevicesMap[deviceHealth.healthStatus],
            },
          ]
        : [],
    );
  }, [devicesHealthData]);

  const { data: environment } = useQuery(
    [ENVIRONMENT_NAME_QUERY_KEY, customerEnvId],
    () => ApplicationsService.getEnvironment(customerEnvId),
    { enabled: !!customerEnvId },
  );

  const wdacDateRange = useMemo(() => {
    if (!startDate || !endDate) return null;

    const start = new Date(startDate);
    const end = new Date(endDate);

    const timeDifference = end.getTime() - start.getTime();
    const dayDifference = timeDifference / (1000 * 3600 * 24);

    if (end.getTime() === start.getTime()) return 1;
    if (dayDifference === 0) return 5;
    if (dayDifference < 0) return null;

    return Math.floor(dayDifference) + 1;
  }, [startDate, endDate]);

  const isEndDateToday = useMemo(() => {
    if (!endDate) return false;
    const end = new Date(endDate);
    const today = new Date();
    return end.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
  }, [endDate]);

  const onEnforcementStatusSlotClick = useCallback(
    data => {
      console.log(data, startDate, endDate, data?.label);
      switch (data?.label) {
        case 1:
        case 'Audit':
          return navigate(
            `${PORTAL_ROUTE}/devices?filters=${JSON.stringify(
              getDeviceWdacStatusFilerQuery({
                status: DeviceStatus.audit,
                // startDate: format(startDate, 'yyyy-MM-dd'),
                // endDate: format(endDate, 'yyyy-MM-dd'),
                startDate,
                endDate,
                tagIds,
              }),
            )}`,
          );
        case 2:
        case 'Enforced':
          return navigate(
            `${PORTAL_ROUTE}/devices?filters=${JSON.stringify(
              getDeviceWdacStatusFilerQuery({
                status: DeviceStatus.enforced,
                // startDate: format(startDate, 'yyyy-MM-dd'),
                // endDate: format(endDate, 'yyyy-MM-dd'),
                startDate,
                endDate,
                tagIds,
              }),
            )}`,
          );
        default:
          navigate(
            `${PORTAL_ROUTE}/devices?filters=${JSON.stringify(
              getDeviceWdacStatusFilerQuery({
                status: DeviceStatus.unknown,
                startDate,
                endDate,
                tagIds,
              }),
            )}`,
          );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tagNames, endDate, endDate],
  );

  const onReportSlotClick = data => {
    if (!data?.label) return;

    navigate(
      `${PORTAL_ROUTE}/devices?filters=${JSON.stringify(
        getDeviceHealthStatusFilterQuery({
          status: reportedDevicesLabelToValue[data.label],
          startDate,
          endDate,
          tagIds,
        }),
      )}`,
    );
  };

  const onChangeDateRange = useCallback(
    newDateRange => {
      onApplySearchParams({ deviceDashboardFilters: newDateRange });
    },
    [onApplySearchParams],
  );

  const onApplyTagFilter = useCallback(
    (tagNames, tagIds) => {
      onApplySearchParams({ tagNamesFilters: tagNames, tagIds: JSON.stringify(tagIds) });
    },
    [onApplySearchParams],
  );

  const tagOptions = useMemo(() => {
    if (!tagIds?.length || !tagNames?.length) return [];

    return tagIds.map((tagId, index) => ({
      id: tagId,
      name: tagNames[index],
    }));
  }, [tagNames, tagIds]);

  return (
    <Stack direction="row" gap={4}>
      <Stack gap={4} flex={1}>
        <Typography
          sx={{
            minHeight: '42px',
            fontSize: 28,
            fontWeight: 'bold',
          }}>
          {environment?.name ? `${environment?.name} dashboard` : ''}
        </Typography>
        <DevicesChartTagFilter onApplyTagFilter={onApplyTagFilter} tagOptions={tagOptions} />

        <Stack direction="row" alignItems="stretch" gap={5} flexWrap="wrap" minHeight={'324px'}>
          <DeviceChart
            title={pagesText.deviceDashboard.totalDeviceInEnvironment}
            data={enforcementStatusChartData}
            onItemClick={onEnforcementStatusSlotClick}
            isLoading={enforcementIsLoading}
            chartStyles={{
              '& .MuiChartsLegend-root': {
                transform: 'translateX(-20%)',
              },
            }}
          />

          <DeviceChart
            startChildren={
              <Stack alignItems="end">
                <DatePicker
                  initialStartDate={startDate}
                  initialEndDate={endDate}
                  onChangeDateRange={onChangeDateRange}
                  initialDateRange={wdacDateRange}
                />
              </Stack>
            }
            title={
              isEndDateToday
                ? pagesText.deviceDashboard.devicesOnline.replace('x', wdacDateRange)
                : pagesText.deviceDashboard.devicesOnlineFrom.replace('x', wdacDateRange)
            }
            data={deviceHealthChartData}
            onItemClick={onReportSlotClick}
            isLoading={healthIsLoading}
            chartStyles={{
              '& .MuiChartsLegend-root': {
                transform: 'translateX(-10%)',
              },
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DevicesCharts;
