import { Box, Typography } from '@mui/material';

const ScriptsTab = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography mt={4} variant="h4">
        COMING SOON
      </Typography>
    </Box>
  );
};

export default ScriptsTab;
