import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { pagesText } from '../../constants/pagesText';
import { DEVICES_QUERY_KEY } from '../../constants/query';
import DeviceService from '../../services/device.service';
import useSearchParams from '../../hooks/useSearchParams';
import useSubscribeStorage from '../../hooks/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';

const useMutateDeviceStatus = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { page = 1 } = useSearchParams();
  const parsedPage = Number(page);
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);

  return useMutation(DeviceService.updateStatus, {
    onMutate: async newData => {
      await queryClient.cancelQueries({
        queryKey: [DEVICES_QUERY_KEY, newData.query, customerEnvId],
      });

      const previousDevices = queryClient.getQueryData(DEVICES_QUERY_KEY);

      queryClient.setQueryData([DEVICES_QUERY_KEY, newData.query, customerEnvId], prevData => {
        const updatedPages = prevData.pages.map((page, index) => {
          if (index === parsedPage - 1) {
            const updatedDevices = page.devices.map(device =>
              device.id === newData.deviceId ? { ...device, status: newData.status } : device,
            );
            return { ...page, devices: updatedDevices };
          }
          return page;
        });

        return { ...prevData, pages: updatedPages };
      });

      return { previousDevices };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(
        [DEVICES_QUERY_KEY, newData.query, customerEnvId],
        context.previousDevices,
      );
      enqueueSnackbar(pagesText.somethingWentWrong, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
    onSuccess: () => {
      enqueueSnackbar(pagesText.devices.successFullyUpdateDeviceStatus, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
  });
};

export default useMutateDeviceStatus;
