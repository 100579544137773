import { memo, useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import GeneralTextField from '../../profile/general/GeneralTextField';
import Iconify from '../../../components/Iconify';
import ErrorAlert from '../../profile/general/ErrorAlert';
import { pagesText } from '../../../constants/pagesText';
import { USER_QUERY_KEY } from '../../../constants/query';
import Button from '../../../components/Button';
import EnvironmentIdMenu from './EnvironmentIdMenu';
import { environmentIdLabelsMap, IdField } from './constants';
import { guidRegex, idValidationFields, nameValidationField } from './validationSchema';
import { generateGUID } from '../../../utils/id-generator.util';
import { formatEnvironmentId, parseEnvironmentId } from '../../../utils/environments.util';
import { colors } from '../../../theme/palette/darkPalette';
import CopyButton from '../../../components/CopyButton';
import ConfirmTenantIdField from './ConfirmTenantIdField';
import useSubscribeStorage from 'src/hooks/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';

const EndAdornment = memo(() => (
  <InputAdornment position="end">
    <Iconify icon="mdi:required-circle" color="#DE312B" width={16} height={16} />
  </InputAdornment>
));

const MatchField = memo(() => (
  <Iconify icon="ic:round-check" color="#7DFF92" width={20} height={20} />
));

const EnvironmentForm = ({
  onExitAddMode,
  isEditMode,
  name = '',
  configManagerSupportId = '',
  description = '',
  location = '',
  editEnvironmentId,
  onClose,
  onSuccess,
}) => {
  const { type: environmentIdType, id: parsedEnviId } = parseEnvironmentId(configManagerSupportId);
  const queryClient = useQueryClient();
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);
  const [selectedIdField, setSelectedIdField] = useState(environmentIdType);
  const shouldGenerateKey = useRef(false);
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const [shouldShowCopyField, setShouldShowCopyField] = useState(isEditMode);

  const [serverError, setServerError] = useState('');
  // Todo: we need to handle this error in a better way when we have separate states for each error field on backend
  const isNameFieldInValid = useMemo(() => {
    if (!serverError) return false;

    const nameRegex = /name/gi;

    return nameRegex.test(serverError);
  }, [serverError]);
  // Todo: we need to handle this error in a better way when we have separate states for each error field on backend
  const isSupportIdFieldInValid = useMemo(() => {
    if (!serverError) return false;

    const supportIdRegex = /supportid | support id/gi;

    return supportIdRegex.test(serverError);
  }, [serverError]);

  const isSupportIdNameExists = useMemo(() => {
    if (!serverError) return false;

    const supportIdRegex = /somesupportidname/gi;

    return supportIdRegex.test(serverError);
  }, [serverError]);

  const formik = useFormik({
    initialValues: {
      name,
      configManagerSupportId: parsedEnviId,
      description,
      location,
      applicationManagementSolution: environmentIdType,
    },
    validationSchema: Yup.object({
      name: nameValidationField,
      configManagerSupportId: idValidationFields[selectedIdField],
      applicationManagementSolution: Yup.mixed().required(
        'Application management solution is required',
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      if (!shouldShowCopyField) {
        formik.setFieldError(
          'configManagerSupportId',
          pagesText.environments.supportIdErrorInfoText,
        );
        return;
      }
      if (isEditMode) {
        onSuccess(
          {
            ...values,
            id: editEnvironmentId,
            configManagerSupportId: formatEnvironmentId(
              selectedIdField,
              values.configManagerSupportId,
            ),
          },
          {
            onSuccess: () => {
              onExitAddMode();
              resetForm();
              setServerError('');
              onClose();
            },
            onError: error => {
              setServerError(error);
            },
          },
        );

        return;
      }

      onSuccess(
        {
          ...values,
          configManagerSupportId: formatEnvironmentId(
            selectedIdField,
            values.configManagerSupportId,
          ),
        },
        {
          onSuccess: data => {
            onExitAddMode();
            resetForm();
            setServerError('');
            onClose();

            if (!customerEnvId) {
              queryClient.setQueryData(USER_QUERY_KEY, prevProps => ({
                ...prevProps,
                defaultCustomerEnvId: data.id,
              }));
            }
          },
          onError: error => {
            setShouldShowCopyField(false);
            setServerError(error);
          },
        },
      );
    },
    validateOnChange: shouldValidateOnChange,
    validateOnBlur: false,
    enableReinitialize: isEditMode,
  });

  const onChangeSelectedIdField = useCallback(
    value => {
      setSelectedIdField(value);
      formik.setFieldValue('configManagerSupportId', '', false);
      formik.setFieldTouched('configManagerSupportId', false, false);
      shouldGenerateKey.current = false;
      setServerError('');
      setShouldShowCopyField(false);
    },
    [setSelectedIdField, setShouldShowCopyField, formik],
  );

  const checkShowEndAdornment = useCallback(
    field => {
      if (isEditMode) return false;

      const noErrorTouched =
        (!formik.errors || !formik.errors[field]) && formik.touched && !formik.touched[field];
      const errorNotTouched = !!formik.errors[field] && !formik.touched[field];

      return noErrorTouched || errorNotTouched;
    },
    [formik.errors, formik.touched, isEditMode],
  );

  const applicationManagementSolution = useMemo(
    () => formik.getFieldProps('applicationManagementSolution'),
    [formik],
  );
  const nameFields = useMemo(() => formik.getFieldProps('name'), [formik]);
  const supportIdFields = useMemo(() => formik.getFieldProps('configManagerSupportId'), [formik]);

  useEffect(() => {
    const { configManagerSupportId } = formik.values;

    if (
      selectedIdField === IdField.OTHER &&
      !configManagerSupportId &&
      !shouldGenerateKey.current
    ) {
      formik.setFieldValue('configManagerSupportId', generateGUID(), false);
      formik.setFieldTouched('configManagerSupportId', false, false);
      shouldGenerateKey.current = true;
    }
  }, [formik, selectedIdField]);

  const AdditionalInfoField = useCallback(() => {
    switch (selectedIdField) {
      case IdField.CONFIG_MANAGER:
        return (
          <Typography
            sx={{
              color: colors.green,
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '14px',
              minHeight: '20px',
            }}>
            {pagesText.environments.supportIdInfoText}
          </Typography>
        );
      case IdField.INTUNE:
        return (
          <Link
            target="_blank"
            href="https://learn.microsoft.com/en-us/entra/fundamentals/how-to-find-tenant"
            sx={{
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '14px',
              color: colors.green,
              textDecoration: 'none',
            }}>
            {pagesText.environments.intuneInfoText}
          </Link>
        );
      default:
        return null;
    }
  }, [selectedIdField]);

  const shouldShowConfirmTenantIdField = useMemo(() => {
    if (!shouldShowCopyField) {
      return selectedIdField !== IdField.OTHER && formik?.values?.configManagerSupportId;
    } else {
      return (
        selectedIdField !== IdField.OTHER &&
        formik?.values?.configManagerSupportId &&
        !formik?.errors?.configManagerSupportId
      );
    }
  }, [formik, selectedIdField, shouldShowCopyField]);

  const validateIdField = useCallback(
    value => {
      formik.setTouched({ ...formik.touched, configManagerSupportId: true });
      if (!value && formik.touched.configManagerSupportId) {
        formik.setFieldError(
          'configManagerSupportId',
          pagesText.environments.validationMessages[selectedIdField].required,
        );
        return;
      }

      if (
        (value?.length !== 36 && selectedIdField !== IdField.CONFIG_MANAGER) ||
        (selectedIdField === IdField.CONFIG_MANAGER && value?.length !== 44)
      ) {
        formik.setFieldError(
          'configManagerSupportId',
          pagesText.environments.validationMessages[selectedIdField]?.length,
        );
        return;
      }

      if (!guidRegex.test(value) && selectedIdField !== IdField.CONFIG_MANAGER) {
        formik.setFieldError(
          'configManagerSupportId',
          pagesText.environments.validationMessages[selectedIdField]?.guid,
        );
      }

      formik.setFieldError('configManagerSupportId', '');
    },
    [selectedIdField, formik],
  );

  const ConfirmTenantField = useMemo(
    () =>
      shouldShowConfirmTenantIdField
        ? () => (
            <ConfirmTenantIdField
              onClick={() => {
                setShouldShowCopyField(true);
              }}
              selectedIdField={selectedIdField}
            />
          )
        : () => <Box width={70} height={40} />,
    [shouldShowConfirmTenantIdField, setShouldShowCopyField, selectedIdField],
  );

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        onSubmit={event => {
          event.preventDefault();
          setShouldValidateOnChange(true);
          formik.handleSubmit();
        }}
        style={{ height: '100%' }}>
        <Stack gap={3} pt={1} height="100%">
          <Stack justifyContent="space-between" gap={2} height="100%">
            <Stack gap={2} pt={1}>
              <EnvironmentIdMenu
                {...applicationManagementSolution}
                onChange={event => {
                  applicationManagementSolution.onChange(event);
                  onChangeSelectedIdField(event.target.value);
                }}
                error={
                  !!formik.touched.applicationManagementSolution &&
                  formik.errors.applicationManagementSolution
                }
              />
              <GeneralTextField
                label="Name"
                error={(!!formik.errors.name && !!formik.touched.name) || isNameFieldInValid}
                helperText={!!formik.touched.name && formik.errors.name}
                endAdornment={
                  checkShowEndAdornment('name') && !nameFields.value ? <EndAdornment /> : null
                }
                FieldComponent={
                  !formik.errors.name && !!nameFields.value && !isNameFieldInValid
                    ? MatchField
                    : null
                }
                {...nameFields}
              />
              {isNameFieldInValid && (
                <ErrorAlert
                  title="Environment Name Already Exists"
                  description="Please pick a different name"
                />
              )}

              {selectedIdField !== IdField.OTHER && selectedIdField && !shouldShowCopyField && (
                <GeneralTextField
                  {...supportIdFields}
                  fieldComponentWidth={shouldShowConfirmTenantIdField ? 200 : 70}
                  readOnly={selectedIdField === IdField.OTHER}
                  AdditionalInfoField={AdditionalInfoField}
                  label={environmentIdLabelsMap[selectedIdField]}
                  error={
                    (!!formik.errors.configManagerSupportId &&
                      !!formik.touched.configManagerSupportId) ||
                    isSupportIdFieldInValid
                  }
                  helperText={
                    !!formik.touched.configManagerSupportId && formik.errors.configManagerSupportId
                  }
                  endAdornment={
                    checkShowEndAdornment('configManagerSupportId') && !supportIdFields.value ? (
                      <EndAdornment />
                    ) : null
                  }
                  onChange={event => {
                    supportIdFields.onChange(event);
                    validateIdField(event.target.value);
                  }}
                  FieldComponent={ConfirmTenantField}
                  wrapperProps={{
                    alignItems: 'flex-start',
                  }}
                />
              )}
              {(shouldShowCopyField || selectedIdField === IdField.OTHER) && (
                <GeneralTextField
                  readOnly
                  value={formatEnvironmentId(selectedIdField, formik.values.configManagerSupportId)}
                  FieldComponent={() => (
                    <CopyButton
                      copyText={formatEnvironmentId(
                        selectedIdField,
                        formik.values.configManagerSupportId,
                      )}
                    />
                  )}
                />
              )}
              {isSupportIdFieldInValid && (
                <ErrorAlert
                  title={`${environmentIdLabelsMap[selectedIdField]} already exists.`}
                  description={`Please check for your ${environmentIdLabelsMap[selectedIdField]}`}
                />
              )}
              {isSupportIdNameExists && (
                <ErrorAlert description="Support Id Name cannot be changed as the Application Catalog has been created" />
              )}
              <GeneralTextField
                variant="standard"
                label="Description"
                {...formik.getFieldProps('description')}
              />
              <GeneralTextField
                variant="standard"
                label="Location"
                {...formik.getFieldProps('location')}
              />
            </Stack>
            <Stack direction="row" gap={1.75} justifyContent="end">
              <Button
                variant="text"
                onClick={onClose}
                styles={{
                  color: '#B5B5B5',
                  textTransform: 'capitalize',
                }}>
                Cancel
              </Button>
              <Button
                variant="outlined"
                type="submit"
                styles={{
                  textTransform: 'capitalize',
                }}>
                Save
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default memo(EnvironmentForm);
