import { useQuery } from 'react-query';
import { DEVICE_QUERY_KEY } from '../../constants/query';
import DeviceService from '../../services/device.service';
import useSubscribeStorage from '../../hooks/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';

const useDevice = ({ deviceId }) => {
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);

  return useQuery([DEVICE_QUERY_KEY, deviceId], () =>
    DeviceService.getDevice({ deviceId: deviceId, customerEnvId: customerEnvId }),
  );
};

export default useDevice;
