import CustomHeadingCell from './CustomHeadingCell';

export const columnsOrder = [
  'environmentApplicationName',
  'installBlockedByDynamicCodeSecurity',
  'executionBlockedByDynamicCodeSecurity',
  'installTrustedByISG',
  'executionTrustedByISG',
  'executionTrustedByISGDate',
  'installTrustedByISGDate',
  'filesCount',
];

export const headings = [
  {
    title: 'Name',
    name: 'name',
    Component: CustomHeadingCell,
  },
  {
    title: 'Value',
    name: 'value',
    Component: CustomHeadingCell,
  },
];

export const publishersHeadings = [
  {
    title: 'Publisher',
    name: 'publisherName',
  },
  {
    title: 'Valid From',
    name: 'validFrom',
  },
  {
    title: 'Valid To',
    name: 'validTo',
  },
  {
    title: 'TBS Hash',
    name: 'tbsHash',
    styles: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '200px',
    },
  },
];

export const publishersColumnData = ['publisherName', 'validFrom', 'validTo', 'tbsHash'];

export const unsignedFilesHeadings = [
  {
    title: 'File Path',
    name: 'filePath',
  },
  {
    title: 'File Name',
    name: 'fileName',
  },
];

export const unsignedFilesColumnData = ['filePath', 'fileName'];

export const outsideFilesHeading = [
  {
    title: 'File Path',
    name: 'filePath',
  },
  {
    title: 'File Name',
    name: 'fileName',
  },
];

export const outsideFilesColumnData = ['filePath', 'fileName'];

export const writableFoldersColumnData = ['path'];
export const writableFoldersHeadings = [
  {
    title: 'Path',
    name: 'path',
  },
];

export const EXPANDED_ROWS_LIMIT = 15;
